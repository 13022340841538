export const TYPES_CONTACT_FORM = {
  CONTACT_REQUEST_FORM: 'CONTACT_REQUEST_FORM',
  CONTACT_SUCCESS_FORM: 'CONTACT_SUCCESS_FORM',
  CONTACT_ERROR_FORM: 'CONTACT_ERROR_FORM',
};

const initialValues = {
  loading: false,
  dataSuccess: null,
  errMessages: null,
};

const contactFormData = (state = initialValues, { type, payload }) => {
  switch (type) {
    case TYPES_CONTACT_FORM.CONTACT_REQUEST_FORM:
      return { ...state, loading: true };
    case TYPES_CONTACT_FORM.CONTACT_SUCCESS_FORM:
      return { ...state, loading: false, dataSuccess: payload };
    case TYPES_CONTACT_FORM.CONTACT_ERROR_FORM:
      return { ...state, loading: false, errMessages: payload };
    default:
      return state;
  }
};

export default contactFormData;

export const contactFormRequest = (data) => ({
  type: TYPES_CONTACT_FORM.CONTACT_REQUEST_FORM,
  payload: data,
});

export const contactFormSuccess = (data) => ({
  type: TYPES_CONTACT_FORM.CONTACT_SUCCESS_FORM,
  payload: data,
});

export const contactFormError = (err) => ({
  type: TYPES_CONTACT_FORM.CONTACT_ERROR_FORM,
  payload: err,
});

export const SELECTORS = {
  getFormData: (state) => state.contactFormData,
};
