import React, { memo, forwardRef } from 'react';

import './Button.scss';

const Button = memo(
  forwardRef(({ type, children, onClick, variant, className, disabled, form }, ref) => (
    <button
      ref={ref}
      type={type || 'button'}
      disabled={disabled}
      onClick={onClick}
      className={`${variant || 'button'} ${className || ''}`}
      form={form}
    >
      {children}
    </button>
  ))
);

export default Button;
