export const TYPES_CAREER_ENQUIRY_FORM = {
  CAREER_ENQUIRY_REQUEST_FORM: 'CAREER_ENQUIRY_REQUEST_FORM',
  CAREER_ENQUIRY_SUCCESS_FORM: 'CAREER_ENQUIRY_SUCCESS_FORM',
  CAREER_ENQUIRY_ERROR_FORM: 'CAREER_ENQUIRY_ERROR_FORM',
};

const initialValues = {
  loading: false,
  dataSuccess: null,
  errMessages: null,
};

const careerEnquiryFormData = (state = initialValues, { type, payload }) => {
  switch (type) {
    case TYPES_CAREER_ENQUIRY_FORM.CAREER_ENQUIRY_REQUEST_FORM:
      return { ...state, loading: true };
    case TYPES_CAREER_ENQUIRY_FORM.CAREER_ENQUIRY_SUCCESS_FORM:
      return { ...state, loading: false, dataSuccess: payload };
    case TYPES_CAREER_ENQUIRY_FORM.CAREER_ENQUIRY_ERROR_FORM:
      return { ...state, loading: false, errMessages: payload };
    default:
      return state;
  }
};

export default careerEnquiryFormData;

export const careerEnquiryRequest = (data) => ({
  type: TYPES_CAREER_ENQUIRY_FORM.CAREER_ENQUIRY_REQUEST_FORM,
  payload: data,
});

export const careerEnquirySuccess = (data) => ({
  type: TYPES_CAREER_ENQUIRY_FORM.CAREER_ENQUIRY_SUCCESS_FORM,
  payload: data,
});

export const careerEnquiryError = (err) => ({
  type: TYPES_CAREER_ENQUIRY_FORM.CAREER_ENQUIRY_ERROR_FORM,
  payload: err,
});

export const SELECTORS = {
  getFormData: (state) => state.careerEnquiryFormData,
};
