import { takeEvery, put, fork } from 'redux-saga/effects';

import API from 'resources/api';
import { GET_SUPPORT_AREA } from 'resources/schema';
import { TYPES_SUPPORT_AREA, requestSupportAreaSuccess, requestSupportAreaError } from 'store/ducks/support';

function* watchSupportAreaData() {
  yield takeEvery(TYPES_SUPPORT_AREA.GET_SUPPORT_AREA_REQUEST, getSupportArea);
}

function* getSupportArea() {
  try {
    const supportArea = yield API.get(GET_SUPPORT_AREA);
    yield put(requestSupportAreaSuccess({ ...supportArea.data.data }));
  } catch (err) {
    yield put(requestSupportAreaError(err));
  }
}

export default function* supportAreaSaga() {
  yield fork(watchSupportAreaData);
}
