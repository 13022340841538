import { takeEvery, put, fork } from 'redux-saga/effects';

import axios from 'axios';
import { SIGNUP } from 'resources/schema';
import { TYPES_SIGNUP, signUpSuccess, signUpErr } from 'store/ducks/signup';

function* watchSignUp() {
  yield takeEvery(TYPES_SIGNUP.SIGNUP_REQUEST, handleSignUp);
}

function* handleSignUp({ payload }) {
  try {
    const signUpData = yield new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: `https://app.projectxprod.heighton.agency/api/${SIGNUP}`,
        data: payload,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          console.log(error);
          console.log(reject);
        });
    });
    yield put(signUpSuccess(signUpData.data));
  } catch (e) {
    yield put(signUpErr(e.response.data));
  }
}

export default function* signUpSaga() {
  yield fork(watchSignUp);
}
