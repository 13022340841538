import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { authSuccess, logoutRequest, TOKEN_STORAGE_KEY } from 'store/ducks/auth';
import API from 'resources/api';
import i18next from 'resources/localization';

const APIProvider = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  API.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem(TOKEN_STORAGE_KEY);
      // eslint-disable-next-line no-param-reassign
      config.headers = {
        Authorization: `Bearer ${token}`,
        'Content-Language': `${i18next.language}`,
      };
      return config;
    },
    (error) => {
      console.warn(error);
    }
  );

  API.interceptors.response.use(
    (response) => {
      if (response.status >= 400 || response.status === undefined) {
        if (response.status === 405 || response.status === 401) {
          dispatch(logoutRequest());
        }
      }
      return response;
    },
    (error) => {
      console.warn(error);
      if (error.response.status === 405 || error.response.status === 401) {
        dispatch(authSuccess(null));
        history.push('/login');
        localStorage.removeItem('token');
      }
      return error;
    }
  );

  return <>{children}</>;
};

export default APIProvider;
