import React, { memo } from 'react';
import classNames from 'classnames';

import './Spinner.scss';

const Spinner = memo(({ formLoader = false }) => (
  <div className={classNames('loader', { formLoader })}>
    <div />
    <div />
    <div />
    <div />
  </div>
));

export default Spinner;
