import { takeEvery, put, fork } from 'redux-saga/effects';

import API from 'resources/api';
import { GET_IMAGES } from 'resources/schema';
import { TYPES_IMAGES, requestImagesSuccess, requestImagesError } from 'store/ducks/images';

function* watchImagesData() {
  yield takeEvery(TYPES_IMAGES.GET_IMAGES_REQUEST, getImages);
}

function* getImages() {
  try {
    const images = yield API.get(GET_IMAGES);
    yield put(requestImagesSuccess({ ...images.data.data }));
  } catch (err) {
    yield put(requestImagesError(err));
  }
}

export default function* imagesSaga() {
  yield fork(watchImagesData);
}
