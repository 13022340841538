export const TYPES_DOWNLOAD = {
  DOWNLOAD_REQUEST: 'DOWNLOAD_REQUEST',
  DOWNLOAD_SUCCESS: 'DOWNLOAD_SUCCESS',
  DOWNLOAD_ERROR: 'DOWNLOAD_ERROR',
};

const initialValues = {
  loading: false,
  downloadData: {
    status: null,
    message: null,
    data: null,
  },
  err: undefined,
};

const download = (state = initialValues, { type, payload }) => {
  switch (type) {
    case TYPES_DOWNLOAD.DOWNLOAD_REQUEST:
      return { ...state, loading: true };
    case TYPES_DOWNLOAD.DOWNLOAD_SUCCESS:
      return { ...state, loading: false, downloadData: payload };
    case TYPES_DOWNLOAD.DOWNLOAD_ERROR:
      return { ...state, loading: false, err: payload };
    default:
      return state;
  }
};

export default download;

export const downloadRequest = () => ({
  type: TYPES_DOWNLOAD.DOWNLOAD_REQUEST,
});

export const downloadSuccess = (data) => ({
  type: TYPES_DOWNLOAD.DOWNLOAD_SUCCESS,
  payload: data,
});

export const downloadErr = (err) => ({
  type: TYPES_DOWNLOAD.DOWNLOAD_ERROR,
  payload: err,
});

export const DOWNLOAD_SELECTOR = {
  getFiles: (state) => state.download.downloadData,
};
