import { takeEvery, put, fork } from 'redux-saga/effects';

import API from 'resources/api';
import { SUPPORT_FORM } from 'resources/schema';
import { TYPES_SUPPORT_FORM, supportFormSuccess, supportFormError } from 'store/ducks/supportForm';

// POST SUPPORT FORM
function* watchSupportFormData() {
  yield takeEvery(TYPES_SUPPORT_FORM.SUPPORT_REQUEST_FORM, handleSupportForm);
}

function* handleSupportForm({ payload }) {
  try {
    const formData = yield API.post(SUPPORT_FORM, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    yield put(supportFormSuccess(formData.data.status));
  } catch (e) {
    yield put(supportFormError(e.message));
  }
}

export default function* supportFormSaga() {
  yield fork(watchSupportFormData);
}
