export const TYPES_HEADER_PRODUCTS = {
  HEADER_PRODUCTS_REQUEST: 'HEADER_PRODUCTS_REQUEST',
  HEADER_PRODUCTS_SUCCESS: 'HEADER_PRODUCTS_SUCCESS',
  HEADER_PRODUCTS_ERROR: 'HEADER_PRODUCTS_ERROR',

  HEADER_CATEGORIES_REQUEST: 'HEADER_CATEGORIES_REQUEST',
  HEADER_CATEGORIES_SUCCESS: 'HEADER_CATEGORIES_SUCCESS',
  HEADER_CATEGORIES_ERROR: 'HEADER_CATEGORIES_ERROR',
};

const initialValues = {
  loadingProducts: true,
  productsData: null,
  productsErr: null,
  hiddenProducts: false,

  loadingCategories: true,
  categoryId: false,
  categoriesData: null,
  categoriesErr: null,
};

const headerProducts = (state = initialValues, { type, payload }) => {
  switch (type) {
    case TYPES_HEADER_PRODUCTS.HEADER_PRODUCTS_REQUEST:
      return { ...state, categoryId: payload, hiddenProducts: false };
    case TYPES_HEADER_PRODUCTS.HEADER_PRODUCTS_SUCCESS:
      return { ...state, loadingProducts: false, productsData: payload, hiddenProducts: true };
    case TYPES_HEADER_PRODUCTS.HEADER_PRODUCTS_ERROR:
      return { ...state, loadingProducts: false, headerDataErr: payload };

    case TYPES_HEADER_PRODUCTS.HEADER_CATEGORIES_REQUEST:
      return { ...state };
    case TYPES_HEADER_PRODUCTS.HEADER_CATEGORIES_SUCCESS:
      return { ...state, loadingCategories: false, categoriesData: payload };
    case TYPES_HEADER_PRODUCTS.HEADER_CATEGORIES_ERROR:
      return { ...state, loadingCategories: false, categoriesErr: payload };

    default:
      return state;
  }
};

export default headerProducts;

export const headerProductsRequest = (id) => ({
  type: TYPES_HEADER_PRODUCTS.HEADER_PRODUCTS_REQUEST,
  payload: id,
});

export const headerProductsSuccess = (data) => ({
  type: TYPES_HEADER_PRODUCTS.HEADER_PRODUCTS_SUCCESS,
  payload: data,
});

export const headerProductsErr = (err) => ({
  type: TYPES_HEADER_PRODUCTS.HEADER_PRODUCTS_ERROR,
  payload: err,
});

export const headerCategoriesRequest = () => ({
  type: TYPES_HEADER_PRODUCTS.HEADER_CATEGORIES_REQUEST,
});

export const headerCategoriesSuccess = (data) => ({
  type: TYPES_HEADER_PRODUCTS.HEADER_CATEGORIES_SUCCESS,
  payload: data,
});

export const headerCategoriesErr = (err) => ({
  type: TYPES_HEADER_PRODUCTS.HEADER_CATEGORIES_ERROR,
  payload: err,
});

export const HEADER_SELECTORS = {
  getHeaderData: (state) => state.headerProducts,
};
