export const TYPES_SEARCH = {
  GET_SEARCH_REQUEST: 'SEARCH/GET_SEARCH_REQUEST',
  GET_SEARCH_SUCCESS: 'SEARCH/GET_SEARCH_SUCCESS',
  GET_SEARCH_ERROR: 'SEARCH/GET_SEARCH_ERROR',
  SET_SEARCH_TYPE: 'SEARCH/SET_SEARCH_TYPE',
  SET_SEARCH: 'SEARCH/SET_SEARCH',
  SET_OFFSET: 'SEARCH/SET_OFFSET',
};

const initialState = {
  loading: false,
  error: false,
  searchType: 0,
  offset: 0,
  hasMorePages: false,
  search: [],
};

const searchReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TYPES_SEARCH.GET_SEARCH_REQUEST: {
      return { ...state, loading: true, error: false };
    }
    case TYPES_SEARCH.GET_SEARCH_SUCCESS: {
      const nextStateBase = { ...state, loading: false, hasMorePages: payload.has_more_pages };

      return payload.loadMore
        ? { ...nextStateBase, search: [...state.search, ...payload.result] }
        : { ...nextStateBase, search: payload.result };
    }
    case TYPES_SEARCH.GET_SEARCH_ERROR: {
      return { ...state, loading: false, error: true };
    }
    case TYPES_SEARCH.SET_SEARCH_TYPE: {
      return { ...state, searchType: payload };
    }
    case TYPES_SEARCH.SET_SEARCH: {
      return { ...state, search: payload };
    }
    case TYPES_SEARCH.SET_OFFSET: {
      return { ...state, offset: payload };
    }
    default:
      return state;
  }
};

export default searchReducer;

export const requestSearch = ({ payload }) => ({
  type: TYPES_SEARCH.GET_SEARCH_REQUEST,
  payload,
});

export const requestSearchSuccess = (data) => ({
  type: TYPES_SEARCH.GET_SEARCH_SUCCESS,
  payload: data,
});

export const requestSearchError = (err) => ({
  type: TYPES_SEARCH.GET_SEARCH_ERROR,
  payload: err,
});

export const setSearchType = (data) => ({
  type: TYPES_SEARCH.SET_SEARCH_TYPE,
  payload: data,
});

export const setSearch = (data) => ({
  type: TYPES_SEARCH.SET_SEARCH,
  payload: data,
});

export const setOffset = (data) => ({
  type: TYPES_SEARCH.SET_OFFSET,
  payload: data,
});

export const SELECTORS = {
  getLoading: (state) => state.search.loading,
  getError: (state) => state.search.error,
  getSearchType: (state) => state.search.searchType,
  getOffset: (state) => state.search.offset,
  getHasMorePages: (state) => state.search.hasMorePages,
  getSearch: (state) => state.search.search,
};
