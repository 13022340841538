import React, { memo } from 'react';

const ArrowSide = memo(({ className, color }) => (
  <svg width="7" height="14" className={className} viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.585052 0.223774L0.19415 0.682363C0.0720717 0.82669 0.00481777 1.01871 0.00481777 1.22393C0.00481777 1.42904
    0.0720717 1.62128 0.19415 1.76561L4.6227 6.99687L0.189236 12.2342C0.0671576 12.3783 1.70465e-08 12.5705
    1.46006e-08 12.7756C1.21548e-08 12.9807 0.0671576 13.1731 0.189236 13.3173L0.577729 13.776C0.830365 14.0747
    1.24189 14.0747 1.49452 13.776L6.79207 7.54037C6.91405 7.39627 7 7.20425 7 6.99733L7 6.99494C7 6.78971 6.91396
    6.5977 6.79207 6.4536L1.50888 0.223774C1.3869 0.0794478 1.21944 0.000227943 1.04581 1.24711e-08C0.872085
    1.03995e-08 0.706938 0.0794478 0.585052 0.223774Z"
      fill={color ? '#fff' : '#3b3b3b'}
    />
  </svg>
));

export default ArrowSide;
