import React, { memo } from 'react';

const SearchIcon = memo(({ darkMode }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.32796 14.6553C9.14304 14.6553 10.8026 13.9881 12.0843 12.8913L17.0243 17.8331C17.1368 17.9441 17.2826 18 17.4285 18C17.5748 18 17.7207
      17.9441 17.8317 17.8331C17.9445 17.7203 18 17.5758 18 17.4285C18 17.2826 17.9445 17.1367 17.8317 17.0253L12.8917 12.0839C13.9884 10.8035
      14.6559 9.14377 14.6559 7.32818C14.6559 3.28741 11.367 0 7.32796 0C3.28715 0 0 3.28741 0 7.32818C0 11.3679 3.28715 14.6553 7.32796
      14.6553ZM7.32796 1.14124C10.7375 1.14124 13.5133 3.91693 13.5133 7.32818C13.5133 10.738 10.7375 13.5141 7.32796 13.5141C3.91697 13.5141
      1.14257 10.738 1.14257 7.32818C1.14257 3.91693 3.91697 1.14124 7.32796 1.14124Z"
      fill={darkMode ? 'var(--white)' : '#4A4F54'}
    />
  </svg>
));

export default SearchIcon;
