import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';

import store from 'store';
import i18next from 'resources/localization';
import APIProvider from 'containers/layouts/APIProvider';
import Routes from 'containers/routes';

const Root = () => (
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <BrowserRouter>
        <APIProvider>
          <Routes />
        </APIProvider>
      </BrowserRouter>
    </I18nextProvider>
  </Provider>
);

export default Root;
