import { takeEvery, put, fork } from 'redux-saga/effects';

import API from 'resources/api';
import { CAREER_ENQUERIES_FORM } from 'resources/schema';
import { TYPES_CAREER_ENQUIRY_FORM, careerEnquirySuccess, careerEnquiryError } from 'store/ducks/careerEnquiryForm';

// POST CAREER ENQUIRY FORM
function* watchCareerEnquiryFormData() {
  yield takeEvery(TYPES_CAREER_ENQUIRY_FORM.CAREER_ENQUIRY_REQUEST_FORM, handleCareerEnquiryForm);
}

function* handleCareerEnquiryForm({ payload }) {
  try {
    const formData = yield API.post(CAREER_ENQUERIES_FORM, payload);
    yield put(careerEnquirySuccess(formData.data.status));
  } catch (e) {
    yield put(careerEnquiryError(e.message));
  }
}

export default function* careerEnquiryFormSaga() {
  yield fork(watchCareerEnquiryFormData);
}
