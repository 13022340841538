import { takeEvery, put, fork } from 'redux-saga/effects';

import API from 'resources/api';
import { GET_PUBLIC_PAGE, GET_PUBLIC_FOOTER } from 'resources/schema';
import { TYPES_PUBLIC_PAGE, requestPublicPageSuccess, requestPublicPageError } from 'store/ducks/public';
import {
  TYPES_PUBLIC_FOOTER,
  requestPublicFooterSuccess,
  requestPublicFooterError,
} from 'store/ducks/publicFooterInfo';

function* watchPublicPageData() {
  yield takeEvery(TYPES_PUBLIC_PAGE.GET_PUBLIC_PAGE_REQUEST, getPublicPage);
  yield takeEvery(TYPES_PUBLIC_FOOTER.GET_PUBLIC_FOOTER_REQUEST, getPublicFooter);
}

function* getPublicPage() {
  try {
    const publicPage = yield API.get(GET_PUBLIC_PAGE);
    yield put(requestPublicPageSuccess({ ...publicPage.data.data }));
  } catch (err) {
    yield put(requestPublicPageError(err));
  }
}

function* getPublicFooter() {
  try {
    const publicFooter = yield API.get(GET_PUBLIC_FOOTER);
    yield put(requestPublicFooterSuccess({ ...publicFooter.data.data }));
  } catch (err) {
    yield put(requestPublicFooterError(err));
  }
}

export default function* publicPageSaga() {
  yield fork(watchPublicPageData);
}
