export const TYPES_SUPPORT_FORM = {
  SUPPORT_REQUEST_FORM: 'SUPPORT_REQUEST_FORM',
  SUPPORT_SUCCESS_FORM: 'SUPPORT_SUCCESS_FORM',
  SUPPORT_ERROR_FORM: 'SUPPORT_ERROR_FORM',
};

const initialValues = {
  loading: false,
  dataSuccess: null,
  errMessages: null,
};

const supportFormData = (state = initialValues, { type, payload }) => {
  switch (type) {
    case TYPES_SUPPORT_FORM.SUPPORT_REQUEST_FORM:
      return { ...state, loading: true };
    case TYPES_SUPPORT_FORM.SUPPORT_SUCCESS_FORM:
      return { ...state, loading: false, dataSuccess: payload };
    case TYPES_SUPPORT_FORM.SUPPORT_ERROR_FORM:
      return { ...state, loading: false, errMessages: payload };
    default:
      return state;
  }
};

export default supportFormData;

export const supportFormRequest = (data) => ({
  type: TYPES_SUPPORT_FORM.SUPPORT_REQUEST_FORM,
  payload: data,
});

export const supportFormSuccess = (data) => ({
  type: TYPES_SUPPORT_FORM.SUPPORT_SUCCESS_FORM,
  payload: data,
});

export const supportFormError = (err) => ({
  type: TYPES_SUPPORT_FORM.SUPPORT_ERROR_FORM,
  payload: err,
});

export const SELECTORS = {
  getFormData: (state) => state.supportFormData,
};
