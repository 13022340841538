import * as Yup from 'yup';

const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

export const LoginSchemaValidation = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
});

export const SignUpSchemaValidation = Yup.object().shape({
  email: Yup.string().email('Invalid email').max(150, 'Must be 150 characters or less').required('Required'),
  alternate_email: Yup.string().email('Invalid email'),
  country: Yup.string().min(3, 'Min 3 characters').max(30, 'Must be 30 characters or less').required('Required'),
  name: Yup.string().min(2, 'Min 2 characters').max(50, 'Must be 50 characters or less').required('Required'),
  organisation: Yup.string().min(2, 'Min 2 characters').max(50, 'Must be 50 characters or less').required('Required'),
  phone: Yup.string()
    .min(10, 'Min 10 characters')
    .max(20, 'Must be 20 characters or less')
    .matches(phoneRegExp, 'Invalid phone number')
    .required('Required'),
  message: Yup.string().max(500, 'Must be 500 characters or less'),
});

export const ContactSchemaValidation = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  name: Yup.string().min(2, 'Min 2 characters').max(30, 'Must be 30 characters or less').required('Required'),
  organisation: Yup.string().min(2, 'Min 2 characters').max(20, 'Must be 20 characters or less').required('Required'),
  phone: Yup.string()
    .min(10, 'Min 10 characters')
    .max(20, 'Must be 20 characters or less')
    .matches(phoneRegExp, 'Invalid phone number')
    .required('Required'),
  message: Yup.string().min(1, 'Min 1 characters').max(256, 'Must be 256 characters or less').required('Required'),
});

export const CareerInquirySchemaValidation = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  name: Yup.string().min(2, 'Min 2 characters').max(20, 'Must be 20 characters or less').required('Required'),
  phone: Yup.string()
    .min(10, 'Min 10 characters')
    .max(20, 'Must be 20 characters or less')
    .matches(phoneRegExp, 'Invalid phone number')
    .required('Required'),
});

export const DownloadFormSchemaValidation = Yup.object().shape({
  subject: Yup.string().required('Required'),
  software: Yup.string().required('Required'),
  email: Yup.string().required('Required'),
  // message: Yup.string().required('Required'),
});

export const SupportSchemaValidationStepOne = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  name: Yup.string().min(2, 'Min 2 characters').max(20, 'Must be 20 characters or less').required('Required'),
  organisation: Yup.string().min(2, 'Min 2 characters').max(20, 'Must be 20 characters or less').required('Required'),
  phone: Yup.string()
    .min(10, 'Min 10 characters')
    .max(20, 'Must be 20 characters or less')
    .matches(phoneRegExp, 'Invalid phone number')
    .required('Required'),
});

export const SupportSchemaValidationStepTwo = Yup.object().shape({
  product_serial_number: Yup.string()
    .min(1, 'Min 1 characters')
    .max(256, 'Must be 256 characters or less')
    .required('Required'),
  software_version: Yup.string()
    .min(1, 'Min 1 characters')
    .max(256, 'Must be 256 characters or less')
    .required('Required'),
  issue_description: Yup.string()
    .min(1, 'Min 1 characters')
    .max(1000, 'Must be 1000 characters or less')
    .required('Required'),
  issue_steps_taken: Yup.string()
    .min(1, 'Min 1 characters')
    .max(1000, 'Must be 1000 characters or less')
    .required('Required'),
});

export const QuestionSchemaValidation = Yup.object().shape({
  subject: Yup.string().min(2, 'Min 2 characters').max(100, 'Must be 100 characters or less').required('Required'),
  relateTo: Yup.string().min(2, 'Min 2 characters').max(100, 'Must be 100 characters or less').required('Required'),
  query: Yup.string().min(10, 'Min 10 characters').max(500, 'Must be 500 characters or less').required('Required'),
});

export const SearchSchemaValidation = Yup.object().shape({
  search: Yup.string().min(3, 'Min 3 characters'),
});
