import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Header from 'components/Header';
import PrivateFooter from 'components/PrivateFooter';

import './PrivateLayout.scss';

const CabinetLayout = ({ children }) => {
  const { pathname } = useLocation();

  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    if (pathname === '/app/home' || pathname === '/app/home/') {
      setDarkMode(true);
    } else {
      setDarkMode(false);
    }
  }, [pathname]);

  return (
    <>
      <Header darkMode={darkMode} />
      <main className="privateLayout">{children}</main>
      <PrivateFooter dark={darkMode} />
    </>
  );
};

export default CabinetLayout;
