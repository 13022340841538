export const TYPES_SPECIAL = {
  SPECIAL_LIST_REQUEST: 'SPECIAL_LIST_REQUEST',
  SPECIAL_LIST_SUCCESS: 'SPECIAL_LIST_SUCCESS',
  SPECIAL_LIST_ERROR: 'SPECIAL_LIST_ERROR',

  SPECIAL_PAGE_REQUEST: 'SPECIAL_PAGE_REQUEST',
  SPECIAL_PAGE_SUCCESS: 'SPECIAL_PAGE_SUCCESS',
  SPECIAL_PAGE_ERROR: 'SPECIAL_PAGE_ERROR',

  SPECIAL_PAGE_FILES_REQUEST: 'SPECIAL_PAGE_FILES_REQUEST',
  SPECIAL_PAGE_FILES_SUCCESS: 'SPECIAL_PAGE_FILES_SUCCESS',
  SPECIAL_PAGE_FILES_ERROR: 'SPECIAL_PAGE_FILES_ERROR',
};

const initialValues = {
  loadingList: true,
  specialListData: null,
  specialListErrorInfo: null,
  specialListFailed: false,

  loadingPage: true,
  pageData: null,
  specialPageInfoError: null,
  specialPageFailed: false,

  loadingFiles: true,
  dataFiles: null,
  dataFilesError: null,
  dataFilesFailed: false,
};

const special = (state = initialValues, { type, payload }) => {
  switch (type) {
    case TYPES_SPECIAL.SPECIAL_LIST_REQUEST:
      return { ...state };
    case TYPES_SPECIAL.SPECIAL_LIST_SUCCESS:
      return { ...state, loadingList: false, specialListData: payload };
    case TYPES_SPECIAL.SPECIAL_LIST_ERROR:
      return { ...state, loadingList: false, specialListFailed: true, specialListErrorInfo: payload };

    case TYPES_SPECIAL.SPECIAL_PAGE_REQUEST:
      return { ...state };
    case TYPES_SPECIAL.SPECIAL_PAGE_SUCCESS:
      return { ...state, loadingPage: false, pageData: payload };
    case TYPES_SPECIAL.SPECIAL_PAGE_ERROR:
      return { ...state, loadingPage: false, specialPageFailed: true, specialPageInfoError: payload };

    case TYPES_SPECIAL.SPECIAL_PAGE_FILES_REQUEST:
      return { ...state };

    case TYPES_SPECIAL.SPECIAL_PAGE_FILES_SUCCESS:
      return { ...state, loadingFiles: false, dataFiles: payload };

    case TYPES_SPECIAL.SPECIAL_PAGE_FILES_ERROR:
      return { ...state, loadingFiles: false, dataFilesFailed: true, dataFilesError: payload };

    default:
      return state;
  }
};

export default special;

export const specialListRequest = () => ({
  type: TYPES_SPECIAL.SPECIAL_LIST_REQUEST,
});

export const specialListSuccess = (data) => ({
  type: TYPES_SPECIAL.SPECIAL_LIST_SUCCESS,
  payload: data,
});

export const specialListError = (err) => ({
  type: TYPES_SPECIAL.SPECIAL_LIST_REQUEST,
  payload: err,
});

export const specialPageRequest = (id) => ({
  type: TYPES_SPECIAL.SPECIAL_PAGE_REQUEST,
  payload: id,
});

export const specialPageSuccess = (data) => ({
  type: TYPES_SPECIAL.SPECIAL_PAGE_SUCCESS,
  payload: data,
});

export const specialPageError = (err) => ({
  type: TYPES_SPECIAL.SPECIAL_PAGE_ERROR,
  payload: err,
});

export const specialPageFilesRequest = (id) => ({
  type: TYPES_SPECIAL.SPECIAL_PAGE_FILES_REQUEST,
  payload: id,
});

export const specialPageFilesSuccess = (data) => ({
  type: TYPES_SPECIAL.SPECIAL_PAGE_FILES_SUCCESS,
  payload: data,
});

export const specialPageFilesError = (err) => ({
  type: TYPES_SPECIAL.SPECIAL_PAGE_FILES_SUCCESS,
  payload: err,
});

export const SPECIAL_SELECTORS = {
  getList: (state) => state.special,
  getPage: (state) => state.special,
};
