import { takeEvery, put, fork } from 'redux-saga/effects';

import API from 'resources/api';
import { FETCH_CALENDAR_TIMELINES } from 'resources/schema';
import {
  TYPES_CALENDAR_TIMELINES,
  requestCalendarTimelinesSuccess,
  requestCalendarTimelinesError,
} from 'store/ducks/calendar';

function* watchCalendarTimelinesData() {
  yield takeEvery(TYPES_CALENDAR_TIMELINES.GET_TIMELINES_REQUEST, getCalendarTimelines);
}

function* getCalendarTimelines({ payload: { categoryId, offset, limit, timelineId, loadMore = false } }) {
  try {
    const calendarTimelines = yield API.get(FETCH_CALENDAR_TIMELINES({ categoryId, offset, limit, timelineId }));
    yield put(requestCalendarTimelinesSuccess({ ...calendarTimelines.data.data, loadMore, categoryId, timelineId }));
  } catch (err) {
    yield put(requestCalendarTimelinesError(err));
  }
}

export default function* calendarTimelinesSaga() {
  yield fork(watchCalendarTimelinesData);
}
