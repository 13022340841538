import { takeEvery, put, fork } from 'redux-saga/effects';

import API from 'resources/api';
import { SEARCH } from 'resources/schema';
import { TYPES_SEARCH, requestSearchSuccess, requestSearchError } from 'store/ducks/search';

function* watchSearchData() {
  yield takeEvery(TYPES_SEARCH.GET_SEARCH_REQUEST, getSearch);
}

function* getSearch({ payload: { searchType, searchText, offset, limit, loadMore = false } }) {
  try {
    const search = yield API.get(SEARCH({ searchType, searchText, offset, limit }));
    yield put(requestSearchSuccess({ ...search.data.data, loadMore, searchType }));
  } catch (err) {
    yield put(requestSearchError(err));
  }
}

export default function* searchSaga() {
  yield fork(watchSearchData);
}
