import { takeEvery, put, fork } from 'redux-saga/effects';

import API from 'resources/api';
import { LOGIN } from 'resources/schema';
import { TYPES_LOGIN, loginSuccess, loginErr } from 'store/ducks/login';

function* watchLogin() {
  yield takeEvery(TYPES_LOGIN.LOGIN_REQUEST, handleLogin);
}

function* handleLogin({ payload }) {
  try {
    const loginData = yield API.post(LOGIN, payload);
    yield put(loginSuccess(loginData));
  } catch (e) {
    yield put(loginErr(e));
  }
}

export default function* loginSaga() {
  yield fork(watchLogin);
}
