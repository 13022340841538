export const TYPES_SUPPORT_AREA = {
  GET_SUPPORT_AREA_REQUEST: 'GET_SUPPORT_AREA_REQUEST',
  GET_SUPPORT_AREA_SUCCESS: 'GET_SUPPORT_AREA_SUCCESS',
  GET_SUPPORT_AREA_ERROR: 'GET_SUPPORT_AREA_ERROR',
};

const initialState = {
  loading: false,
  error: null,
  data: null,
};

const supportAreaReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TYPES_SUPPORT_AREA.GET_SUPPORT_AREA_REQUEST: {
      return { ...state, loading: true, error: null };
    }
    case TYPES_SUPPORT_AREA.GET_SUPPORT_AREA_SUCCESS: {
      return { ...state, loading: false, data: payload };
    }
    case TYPES_SUPPORT_AREA.GET_SUPPORT_AREA_ERROR: {
      return { ...state, loading: false, error: payload };
    }
    default:
      return state;
  }
};

export default supportAreaReducer;

export const requestSupportArea = () => ({
  type: TYPES_SUPPORT_AREA.GET_SUPPORT_AREA_REQUEST,
});

export const requestSupportAreaSuccess = (data) => ({
  type: TYPES_SUPPORT_AREA.GET_SUPPORT_AREA_SUCCESS,
  payload: data,
});

export const requestSupportAreaError = (err) => ({
  type: TYPES_SUPPORT_AREA.GET_SUPPORT_AREA_ERROR,
  payload: err,
});

export const SELECTORS = {
  getLoading: (state) => state.support.loading,
  getError: (state) => state.support.error,
  getSupportArea: (state) => state.support.data,
};
