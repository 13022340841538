import { takeEvery, put, fork } from 'redux-saga/effects';

import API from 'resources/api';
import { GET_ABOUT } from 'resources/schema';
import { TYPES_ABOUT, aboutSuccess, aboutErr } from 'store/ducks/about';

function* watchAboutData() {
  yield takeEvery(TYPES_ABOUT.ABOUT_REQUEST, handleAboutData);
}

function* handleAboutData() {
  try {
    const aboutData = yield API.get(GET_ABOUT);
    yield put(aboutSuccess(aboutData.data.data));
  } catch (e) {
    yield put(aboutErr(e.response));
  }
}

export default function* aboutSaga() {
  yield fork(watchAboutData);
}
