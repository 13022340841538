import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';

import { USER_ROLES } from 'helpers/arrays';
import { AUTH_SELECTOR } from 'store/ducks/auth';

const hiddenRoutes = ['/app/downloads', '/app/support'];

const UserAccess = ({ children }) => {
  const location = useLocation();
  const { currentUser } = useSelector(AUTH_SELECTOR.getAuth);

  const userRole = currentUser?.data?.role;

  if (userRole === USER_ROLES.LEVEL_4) {
    return <Redirect to="/auth/welcome" />;
  }

  if (userRole === USER_ROLES.LEVEL_3 && hiddenRoutes.includes(location.pathname)) {
    return <Redirect to="/app/home" />;
  }

  return children;
};

export default UserAccess;
