import { useEffect } from 'react';

const useOutsideClick = ({ containerRefs, onClose }) => {
  const handleOutsideClick = ({ target }) => {
    const shouldClose = containerRefs.every((ref) => !ref.current?.contains(target));

    if (shouldClose) {
      onClose();
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, []);
};

export default useOutsideClick;
