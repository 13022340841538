export const TYPES_DOWNLOAD_FORM = {
  DOWNLOAD_REQUEST_FORM: 'DOWNLOAD_REQUEST_FORM',
  DOWNLOAD_SUCCESS_FORM: 'DOWNLOAD_SUCCESS_FORM',
  DOWNLOAD_ERROR_FORM: 'DOWNLOAD_ERROR_FORM',
};

const initialValues = {
  loading: false,
  dataSuccess: null,
  errMessages: null,
};

const downloadFormData = (state = initialValues, { type, payload }) => {
  switch (type) {
    case TYPES_DOWNLOAD_FORM.DOWNLOAD_REQUEST_FORM:
      return { ...state, loading: true };
    case TYPES_DOWNLOAD_FORM.DOWNLOAD_SUCCESS_FORM:
      return { ...state, loading: false, dataSuccess: payload };
    case TYPES_DOWNLOAD_FORM.DOWNLOAD_ERROR_FORM:
      return { ...state, loading: false, errMessages: payload };
    default:
      return state;
  }
};

export default downloadFormData;

export const downloadFormRequest = (data) => ({
  type: TYPES_DOWNLOAD_FORM.DOWNLOAD_REQUEST_FORM,
  payload: data,
});

export const downloadFormSuccess = (data) => ({
  type: TYPES_DOWNLOAD_FORM.DOWNLOAD_SUCCESS_FORM,
  payload: data,
});

export const downloadFormError = (err) => ({
  type: TYPES_DOWNLOAD_FORM.DOWNLOAD_ERROR_FORM,
  payload: err,
});

export const SELECTORS = {
  getFormData: (state) => state.downloadFormData,
};
