import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory, Redirect } from 'react-router-dom';

import { authRequest, authSetFail, AUTH_SELECTOR, TOKEN_STORAGE_KEY } from 'store/ducks/auth';
import Spinner from 'components/Spinner';

import './PrivateLayout.scss';

const PrivateLayout = memo(({ children }) => {
  const dispatch = useDispatch();

  const history = useHistory();
  const { search } = useLocation();

  const { loading, failed, logoutSuccess } = useSelector(AUTH_SELECTOR.getAuth);

  useEffect(() => {
    if (search.length > 0 && search.includes('?token=')) {
      const setToken = search.replace('?token=', '');
      localStorage.setItem(TOKEN_STORAGE_KEY, setToken);
      history.replace('/app/home');
    }
  }, [search]);

  useEffect(() => {
    const getToken = localStorage.getItem(TOKEN_STORAGE_KEY);

    if (getToken) {
      dispatch(authRequest());
    }

    if (!getToken) {
      dispatch(authSetFail());
    }
  }, []);

  if (loading) {
    return (
      <main className="spinnerContainer">
        <Spinner />
      </main>
    );
  }

  if (failed) {
    return <Redirect to="/auth/welcome" />;
  }

  if (logoutSuccess) {
    return <Redirect to="/auth/login" />;
  }

  return <>{children}</>;
});

export default PrivateLayout;
