export const TYPES_CONTACT = {
  CONTACT_REQUEST: 'CONTACT_REQUEST',
  CONTACT_SUCCESS: 'CONTACT_SUCCESS',
  CONTACT_ERROR: 'CONTACT_ERROR',
};

const initialState = {
  loading: false,
  data: {
    departmentContacts: {},
    locations: [],
    main_address: null,
    sales_contacts: null,
    image: null,
    mobile_image: null,
  },
  err: null,
};

const contactReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TYPES_CONTACT.CONTACT_REQUEST:
      return { ...state, loading: true };
    case TYPES_CONTACT.CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          departmentContacts: payload.department_contacts,
          locations: payload.locations,
          image: payload.image,
          mobile_image: payload.mobile_image,
        },
      };
    case TYPES_CONTACT.CONTACT_ERROR:
      return { ...state, loading: false, err: payload };
    default:
      return state;
  }
};

export default contactReducer;

export const contactRequest = () => ({
  type: TYPES_CONTACT.CONTACT_REQUEST,
});

export const contactSuccess = (data) => ({
  type: TYPES_CONTACT.CONTACT_SUCCESS,
  payload: data,
});

export const contactError = (err) => ({
  type: TYPES_CONTACT.CONTACT_ERROR,
  payload: err,
});

export const SELECTORS = {
  getContact: (state) => state.contact.data,
};
