export const TYPES_IMAGES = {
  GET_IMAGES_REQUEST: 'GET_IMAGES_REQUEST',
  GET_IMAGES_SUCCESS: 'GET_IMAGES_SUCCESS',
  GET_IMAGES_ERROR: 'GET_IMAGES_ERROR',
};

const initialState = {
  loading: false,
  error: null,
  data: null,
};

const imagesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TYPES_IMAGES.GET_IMAGES_REQUEST: {
      return { ...state, loading: true, error: null };
    }
    case TYPES_IMAGES.GET_IMAGES_SUCCESS: {
      return { ...state, loading: false, data: payload };
    }
    case TYPES_IMAGES.GET_IMAGES_ERROR: {
      return { ...state, loading: false, error: payload };
    }
    default:
      return state;
  }
};

export default imagesReducer;

export const requestImages = () => ({
  type: TYPES_IMAGES.GET_IMAGES_REQUEST,
});

export const requestImagesSuccess = (data) => ({
  type: TYPES_IMAGES.GET_IMAGES_SUCCESS,
  payload: data,
});

export const requestImagesError = (err) => ({
  type: TYPES_IMAGES.GET_IMAGES_ERROR,
  payload: err,
});

export const SELECTORS = {
  getLoading: (state) => state.images.loading,
  getError: (state) => state.images.error,
  getImages: (state) => state.images.data,
};
