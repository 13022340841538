export const TYPES_SIGNUP = {
  SIGNUP_REQUEST: 'SIGNUP_REQUEST',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  SIGNUP_ERROR: 'SIGNUP_ERROR',
  SIGNUP_SET_STATUS: 'SIGNUP_SET_STATUS',
};

const initialValues = {
  loading: false,
  response: {
    status: undefined,
  },
  err: null,
};

const signUp = (state = initialValues, { type, payload }) => {
  switch (type) {
    case TYPES_SIGNUP.SIGNUP_REQUEST:
      return { ...state, loading: true };
    case TYPES_SIGNUP.SIGNUP_SUCCESS:
      return { ...state, loading: false, response: payload };
    case TYPES_SIGNUP.SIGNUP_SET_STATUS:
      return { ...state, response: { status: undefined } };
    case TYPES_SIGNUP.SIGNUP_ERROR:
      return { ...state, loading: false, err: payload };
    default:
      return state;
  }
};

export default signUp;

export const signUpRequest = (formData) => ({
  type: TYPES_SIGNUP.SIGNUP_REQUEST,
  payload: formData,
});

export const signUpSuccess = (data) => ({
  type: TYPES_SIGNUP.SIGNUP_SUCCESS,
  payload: data,
});

export const signUpSetStatus = () => ({
  type: TYPES_SIGNUP.SIGNUP_SET_STATUS,
});

export const signUpErr = (err) => ({
  type: TYPES_SIGNUP.SIGNUP_ERROR,
  payload: err,
});

export const SIGNUP_SELECTOR = {
  getSignUpResponse: (state) => state.signUp.response,
  getSignUpLoading: (state) => state.signUp,
};
