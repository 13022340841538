// TODO ADD ENDPOINTS
export const FETCH_CALENDAR_TIMELINES = ({ categoryId, limit, offset, timelineId = '' }) =>
  `calendar-timelines?category_id=${categoryId}&limit=${limit}&offset=${offset}&timeline_id=${timelineId}`;

export const SEARCH = ({ limit, offset, searchType, searchText }) =>
  `search?limit=${limit}&offset=${offset}&search_type=${searchType}&search_text=${searchText}`;

export const CONTACT = 'company-contacts';
export const CONTACT_FORM = 'contact-us-enquiries';
export const DOWNLOAD_FORM = 'downloads-issue';
export const CAREER_ENQUERIES_FORM = 'careers-enquiry';
export const SUPPORT_FORM = 'support-enquiries';

export const GET_ABOUT = 'about';

export const SIGNUP = 'registration';
export const LOGIN = 'login';
export const LOGOUT = 'logout';

export const GET_CURRENT_USER = 'user';

export const GET_DOWNLOAD_FILES = 'secret-files/downloads/files';

export const GET_PRIVACY_POLICY = 'privacy-policy';

export const GET_SPECIAL_LIST = 'home-special-pages';
export const GET_SPECIAL_PAGE = 'special-pages';
export const GET_SPECIAL_PAGE_FILES = (id) => `secret-files/special-pages/${id}/files`;

export const GET_PRODUCTS_LIST = ({ offset, limit, categoryId }) =>
  `products?${offset ? `offset=${offset}` : ''}${limit ? `&limit=${limit}` : ''}${
    categoryId ? `&category_id=${categoryId}` : ''
  }`;
export const GET_PRODUCT = (idProduct) => `products/${idProduct}`;
export const GET_PRODUCT_FILES = (idProduct) => `secret-files/products/${idProduct}/files`;
export const GET_CATEGORIES = 'product-categories';

export const GET_IMAGES = 'home-page-images';

export const GET_PUBLIC_PAGE = 'public-page';

export const GET_COMPANY_INFO = 'company-info';

export const GET_MEDIA_RICH = 'media-riches';

export const GET_SUPPORT_AREA = 'support-area';

export const GET_PUBLIC_FOOTER = 'footer-company-info';
