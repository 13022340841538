import { combineReducers } from 'redux';

import calendar from './calendar';
import search from './search';
import contact from './contact';
import contactFormData from './contactFrom';
import about from './about';
import signUp from './signup';
import login from './login';
import auth from './auth';
import download from './download';
import privacy from './privacy';
import special from './special';
import products from './products';
import images from './images';
import publicPage from './public';
import companyInfo from './companyInfo';
import headerProducts from './headerProducts';
import mediaRich from './mediaRich';
import support from './support';
import downloadForm from './downloadForm';
import careerEnquiryForm from './careerEnquiryForm';
import supportForm from './supportForm';
import publicFooter from './publicFooterInfo';

const rootReducer = combineReducers({
  auth,
  download,
  calendar,
  search,
  contact,
  contactFormData,
  about,
  signUp,
  login,
  privacy,
  products,
  special,
  images,
  public: publicPage,
  companyInfo,
  headerProducts,
  mediaRich,
  support,
  downloadForm,
  careerEnquiryForm,
  supportForm,
  publicFooter,
});

export default rootReducer;
