import { takeEvery, put, fork, select } from 'redux-saga/effects';

import API from 'resources/api';
import { GET_PRODUCTS_LIST, GET_CATEGORIES } from 'resources/schema';
import {
  TYPES_HEADER_PRODUCTS,
  headerProductsSuccess,
  headerProductsErr,
  headerCategoriesSuccess,
  headerCategoriesErr,
} from 'store/ducks/headerProducts';

function* watchHeaderProductsData() {
  yield takeEvery(TYPES_HEADER_PRODUCTS.HEADER_PRODUCTS_REQUEST, handleHeaderProducts);
  yield takeEvery(TYPES_HEADER_PRODUCTS.HEADER_CATEGORIES_REQUEST, handleHeaderCategories);
}

function* handleHeaderProducts() {
  try {
    const { categoryId } = yield select((state) => state.headerProducts);
    const headerDataProducts = yield API.get(GET_PRODUCTS_LIST({ offset: 0, limit: 15, categoryId }));
    yield put(headerProductsSuccess(headerDataProducts.data.data));
  } catch (e) {
    yield put(headerProductsErr(e));
  }
}

function* handleHeaderCategories() {
  try {
    const headerCategories = yield API.get(GET_CATEGORIES);
    yield put(headerCategoriesSuccess(headerCategories.data.data));
  } catch (e) {
    yield put(headerCategoriesErr(e.response));
  }
}

export default function* headerProductsSaga() {
  yield fork(watchHeaderProductsData);
}
