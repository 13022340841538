export const TYPES_PRIVACY_POLICY = {
  GET_PRIVACY_POLICY_REQUEST: 'GET_PRIVACY_POLICY_REQUEST',
  GET_PRIVACY_POLICY_SUCCESS: 'GET_PRIVACY_POLICY_SUCCESS',
  GET_PRIVACY_POLICY_ERROR: 'GET_PRIVACY_POLICY_ERROR',
};

const initialState = {
  loading: false,
  error: null,
  data: null,
};

const privacyPolicyReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TYPES_PRIVACY_POLICY.GET_PRIVACY_POLICY_REQUEST: {
      return { ...state, loading: true, error: null };
    }
    case TYPES_PRIVACY_POLICY.GET_PRIVACY_POLICY_SUCCESS: {
      return { ...state, loading: false, data: payload };
    }
    case TYPES_PRIVACY_POLICY.GET_PRIVACY_POLICY_ERROR: {
      return { ...state, loading: false, error: payload };
    }
    default:
      return state;
  }
};

export default privacyPolicyReducer;

export const requestPrivacyPolicy = () => ({
  type: TYPES_PRIVACY_POLICY.GET_PRIVACY_POLICY_REQUEST,
});

export const requestPrivacyPolicySuccess = (data) => ({
  type: TYPES_PRIVACY_POLICY.GET_PRIVACY_POLICY_SUCCESS,
  payload: data,
});

export const requestPrivacyPolicyError = (err) => ({
  type: TYPES_PRIVACY_POLICY.GET_PRIVACY_POLICY_ERROR,
  payload: err,
});

export const SELECTORS = {
  getLoading: (state) => state.privacy.loading,
  getError: (state) => state.privacy.error,
  getPrivacyPolicy: (state) => state.privacy.data,
};
