import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { en } from 'resources/languages/en';
import { de } from 'resources/languages/de';

i18next.on('languageChanged', (lng) => {
  document.documentElement.setAttribute('lng', lng);
});

i18next.use(LanguageDetector).init({
  interpolation: { escapeValue: false },
  fallbackLng: 'en',
  resources: {
    en: {
      translation: en,
    },
    de: {
      translation: de,
    },
  },
  detection: {
    order: ['localStorage'],
    caches: ['localStorage'],
    lookupLocalStorage: 'lng',
  },
});

export default i18next;
