export const TYPES_LOGIN = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
};

const initialState = {
  loading: false,
  data: null,
  err: null,
};

const login = (state = initialState, { type, payload }) => {
  switch (type) {
    case TYPES_LOGIN.LOGIN_REQUEST:
      return { ...state, loading: true };
    case TYPES_LOGIN.LOGIN_SUCCESS:
      return { ...state, loading: false, data: payload };
    case TYPES_LOGIN.LOGIN_ERROR:
      return { ...state, loading: false, err: payload };
    default:
      return state;
  }
};

export default login;

export const loginRequest = (formData) => ({
  type: TYPES_LOGIN.LOGIN_REQUEST,
  payload: formData,
});

export const loginSuccess = (data) => ({
  type: TYPES_LOGIN.LOGIN_SUCCESS,
  payload: data,
});

export const loginErr = (err) => ({
  type: TYPES_LOGIN.LOGIN_ERROR,
  payload: err,
});
