export const TYPES_ABOUT = {
  ABOUT_REQUEST: 'ABOUT_REQUEST',
  ABOUT_SUCCESS: 'ABOUT_SUCCESS',
  ABOUT_ERROR: 'ABOUT_ERROR',
};

const initialValues = {
  loading: false,
  data: {
    about: null,
    time_line: [],
  },
  err: null,
};

const aboutReducer = (state = initialValues, { type, payload }) => {
  switch (type) {
    case TYPES_ABOUT.ABOUT_REQUEST:
      return { ...state, loading: true };
    case TYPES_ABOUT.ABOUT_SUCCESS:
      return { ...state, loading: false, data: payload };
    case TYPES_ABOUT.ABOUT_ERROR:
      return { ...state, loading: false, err: payload };
    default:
      return state;
  }
};

export default aboutReducer;

export const aboutRequest = () => ({
  type: TYPES_ABOUT.ABOUT_REQUEST,
});

export const aboutSuccess = (data) => ({
  type: TYPES_ABOUT.ABOUT_SUCCESS,
  payload: data,
});

export const aboutErr = (err) => ({
  type: TYPES_ABOUT.ABOUT_ERROR,
  payload: err,
});

export const ABOUT_SELECTOR = {
  getAbout: (state) => state.about,
};
