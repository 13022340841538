import React, { memo } from 'react';

const MiniArrowTopDown = memo(({ className, active, darkMode }) => {
  let currentColor;

  if (active) {
    currentColor = 'var(--red)';
  } else if (darkMode) {
    currentColor = 'var(--white)';
  } else {
    currentColor = 'var(--black-400)';
  }

  return (
    <svg
      width="16"
      height="9"
      viewBox="0 0 16 9"
      className={className}
      fill={currentColor}
      style={{ transform: active && 'rotate(0.5turn)' }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.39029 0.823215L1.74155 0.467388L1.38573 0.116123L1.03446 0.47195L1.39029 0.823215ZM1.67495
        1.10423L2.02622 0.7484V0.7484L1.67495 1.10423ZM0.828266 1.39254L0.472439 1.04127L0.121174 1.3971L0.477001
        1.74837L0.828266 1.39254ZM1.11293 1.67355L1.46419 1.31772L1.46419 1.31772L1.11293 1.67355ZM7.41596 7.89577L7.06469
        8.2516V8.2516L7.41596 7.89577ZM7.70062 8.17678L7.34935 8.53261L7.70518 8.88388L8.05645 8.52805L7.70062
        8.17678ZM8.08589 7.78651L8.44176 7.43529L8.08594 7.07475L7.73006 7.43524L8.08589 7.78651ZM8.08726 7.7879L7.73139
        8.13912L8.08265 8.49503L8.43852 8.14372L8.08726 7.7879ZM8.37192 7.50688L8.72319 7.86271V7.86271L8.37192
        7.50688ZM14.281 1.67355L13.9297 1.31772V1.31772L14.281 1.67355ZM14.5657 1.39254L14.9169 1.74837L15.2728
        1.3971L14.9215 1.04127L14.5657 1.39254ZM14.0036 0.823215L14.3595 0.47195L14.0082 0.116123L13.6524 0.467388L14.0036
        0.823215ZM13.719 1.10423L14.0703 1.46005V1.46005L13.719 1.10423ZM7.8099 6.93756L7.45863 6.58173H7.45863L7.8099
        6.93756ZM7.69697 7.04904L7.3457 7.40487L7.69697 7.75163L8.04823 7.40487L7.69697 7.04904ZM1.03902 1.17904L1.32369
        1.46005L2.02622 0.7484L1.74155 0.467388L1.03902 1.17904ZM1.18409 1.7438L1.74612 1.17448L1.03446 0.47195L0.472439
        1.04127L1.18409 1.7438ZM1.46419 1.31772L1.17953 1.03671L0.477001 1.74837L0.761662 2.02938L1.46419 1.31772ZM7.76722
        7.53995L1.46419 1.31772L0.761662 2.02938L7.06469 8.2516L7.76722 7.53995ZM8.05188 7.82096L7.76722 7.53995L7.06469
        8.2516L7.34935 8.53261L8.05188 7.82096ZM7.73006 7.43524L7.34479 7.82552L8.05645 8.52805L8.44172 8.13777L7.73006
        7.43524ZM7.73002 8.13773L7.73139 8.13912L8.44313 7.43668L8.44176 7.43529L7.73002 8.13773ZM8.43852 8.14372L8.72319
        7.86271L8.02066 7.15106L7.73599 7.43207L8.43852 8.14372ZM8.72319 7.86271L14.6323 2.02938L13.9297 1.31772L8.02066
        7.15106L8.72319 7.86271ZM14.6323 2.02938L14.9169 1.74837L14.2144 1.03671L13.9297 1.31772L14.6323 2.02938ZM14.9215
        1.04127L14.3595 0.47195L13.6478 1.17448L14.2098 1.7438L14.9215 1.04127ZM13.6524 0.467388L13.3677 0.748401L14.0703
        1.46005L14.3549 1.17904L13.6524 0.467388ZM13.3677 0.7484L7.45863 6.58173L8.16116 7.29339L14.0703 1.46005L13.3677
        0.7484ZM7.45863 6.58173L7.3457 6.69321L8.04823 7.40487L8.16116 7.29339L7.45863 6.58173ZM1.32369 1.46005L7.3457
        7.40487L8.04823 6.69321L2.02622 0.7484L1.32369 1.46005Z"
      />
    </svg>
  );
});

export default MiniArrowTopDown;
