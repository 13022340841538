import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { AUTH_SELECTOR } from 'store/ducks/auth';
import { SELECTORS as COMPANY_INFO_SELECTORS } from 'store/ducks/companyInfo';
import { USER_ROLES } from 'helpers/arrays';

import { ReactComponent as FooterIcon } from 'assets/icons/footerIcon.svg';
import { ReactComponent as IconLine } from 'assets/images/smallLine.svg';

import './PrivateFooter.scss';

const PrivateFooter = memo(({ dark }) => {
  const [userIP, setUserIP] = useState('xx.xx.xxx.xx');
  const { currentUser } = useSelector(AUTH_SELECTOR.getAuth);
  const companyInfo = useSelector(COMPANY_INFO_SELECTORS.getCompanyInfo);
  useEffect(() => {
    const ip = localStorage.getItem('ip');
    if (ip) {
      setUserIP(ip);
    }
  }, []);

  const getLogo = (ver) => {
    if (ver === 1) {
      return dark ? companyInfo?.first_black_logo : companyInfo?.first_logo;
    }
    return dark ? companyInfo?.second_black_logo : companyInfo?.second_logo;
  };

  return (
    <footer className={classNames('privateFooter', { darkFooter: dark, lightFooter: !dark })}>
      <div className="infoBlock">
        <div className="contactInfo">
          {companyInfo ? (
            <Link to="/app/home">
              <img
                src={currentUser?.data?.role === USER_ROLES.LEVEL_2 ? getLogo(1) : getLogo(2)}
                className="logoFooter"
                alt="logo"
              />
            </Link>
          ) : (
            <div />
          )}

          <div className="infoSecond">
            <div className="title">Contact Sales</div>
            <div className="contact">For further information please contact us:</div>
            <div className="contact">
              UK&nbsp;
              <a href={`tel:${companyInfo?.footer?.phone_number_first}`}>{companyInfo?.footer?.phone_number_first}</a>
              &nbsp;| US&nbsp;
              <a href={`tel:${companyInfo?.footer?.phone_number_second}`}>{companyInfo?.footer?.phone_number_second}</a>
            </div>
            <div className="contact">{`Fax ${companyInfo?.footer?.fax} | ${companyInfo?.footer?.email}`}</div>
          </div>
        </div>

        <div className="privacyInfo">
          {`Your IP address, [${userIP}], has been recorded and all activity on this system is actively monitored.
          Under US Federal Law (18 U.S.C. 1030), United Kingdom Law (Computer Misuse Act 1990) and other international
          law it is a criminal offence to access or attempt to access this computer system without prior written
          authorisation from ${companyInfo?.footer?.company_name} . Any unauthorised attempt to access this system will be reported to the
          appropriate authorities and prosecuted to the full extent of the law.`}
        </div>

        <Link className="privacy-link" to="/app/privacy-policy">
          Privacy Policy
        </Link>
      </div>

      {companyInfo?.footer?.footer_image ? (
        <div className="iconBlockCustom">
          <img src={companyInfo.footer.footer_image} className="icon" alt="icon" />
          <IconLine />
        </div>
      ) : (
        <div className="iconBlock">
          <FooterIcon />
        </div>
      )}
    </footer>
  );
});

export default PrivateFooter;
