import { takeEvery, put, fork } from 'redux-saga/effects';

import API from 'resources/api';
import { GET_CURRENT_USER, LOGOUT } from 'resources/schema';
import { TYPES_AUTH, authSuccess, authErr, logoutSuccess, logoutErr } from 'store/ducks/auth';

function* auth() {
  yield takeEvery(TYPES_AUTH.AUTH_REQUEST, handleAuth);
  yield takeEvery(TYPES_AUTH.LOGOUT_REQUEST, handleLogout);
}

function* handleAuth() {
  try {
    const userData = yield API.get(GET_CURRENT_USER);
    yield put(authSuccess(userData.data));
  } catch (e) {
    yield put(authErr(e.response));
  }
}

function* handleLogout() {
  try {
    yield API.post(LOGOUT);
    localStorage?.removeItem('token');
    yield put(logoutSuccess());
  } catch (e) {
    yield put(logoutErr(e));
  }
}

export default function* authSaga() {
  yield fork(auth);
}
