import React from 'react';

import PublicFooter from 'components/PublicFooter';

import './Auth.scss';

const AuthLayout = ({ children }) => (
  <main className="authLayout">
    <div className="mainContent">{children}</div>
    <PublicFooter />
  </main>
);

export default AuthLayout;
