import { takeEvery, put, fork } from 'redux-saga/effects';

import API from 'resources/api';
import { GET_PRIVACY_POLICY } from 'resources/schema';
import { TYPES_PRIVACY_POLICY, requestPrivacyPolicySuccess, requestPrivacyPolicyError } from 'store/ducks/privacy';

function* watchPrivacyPolicyData() {
  yield takeEvery(TYPES_PRIVACY_POLICY.GET_PRIVACY_POLICY_REQUEST, getPrivacyPolicy);
}

function* getPrivacyPolicy() {
  try {
    const privacyPolicy = yield API.get(GET_PRIVACY_POLICY);
    yield put(requestPrivacyPolicySuccess({ ...privacyPolicy.data.data }));
  } catch (err) {
    yield put(requestPrivacyPolicyError(err));
  }
}

export default function* privacyPolicySaga() {
  yield fork(watchPrivacyPolicyData);
}
