import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import ScrollToTop from 'hooks/useScrollToTop';
import Spinner from 'components/Spinner';
import AuthLayout from 'containers/layouts/Auth/Auth';
import PrivateLayout from 'containers/layouts/Private';
import CabinetLayout from 'containers/layouts/Cabinet';
import UserAccess from 'containers/layouts/UserAccess';

const PublicHomePage = lazy(() => import('containers/pages/PublicHome'));
const LoginPage = lazy(() => import('containers/pages/Login'));
const SignUpPage = lazy(() => import('containers/pages/SignUp'));
const CareerEnquiryPage = lazy(() => import('containers/pages/CareerEnquiry'));
const PrivateHomePage = lazy(() => import('containers/pages/PrivateHome'));
const AboutPage = lazy(() => import('containers/pages/About'));
const SupportPage = lazy(() => import('containers/pages/Support'));
const CalendarPage = lazy(() => import('containers/pages/Calendar'));
const ProductsPage = lazy(() => import('containers/pages/Products'));
const ProductPage = lazy(() => import('containers/pages/Product'));
const SearchPage = lazy(() => import('containers/pages/Search'));
const ContactPage = lazy(() => import('containers/pages/Contact'));
const DownloadsPage = lazy(() => import('containers/pages/Downloads'));
const PrivacyPolicyPage = lazy(() => import('containers/pages/PrivacyPolicy'));
const SpecialPage = lazy(() => import('containers/pages/Special'));
const PublicPrivacyPolicyPage = lazy(() => import('containers/pages/PublicPrivacyPolicy'));

const Fallback = (
  <div className="fallback-loader">
    <Spinner />
  </div>
);

const Routes = () => (
  <>
    <ScrollToTop />
    <Switch>
      <Route path="/auth">
        <AuthLayout>
          <Suspense fallback={Fallback}>
            <Switch>
              <Route path="/auth/welcome" component={PublicHomePage} />
              <Route path="/auth/login" component={LoginPage} />
              <Route path="/auth/sign-up" component={SignUpPage} />
              <Route path="/auth/career-enquiry" component={CareerEnquiryPage} />
              <Route path="/auth/public-privacy-policy" component={PublicPrivacyPolicyPage} />
              <Redirect to="/auth/welcome" />
            </Switch>
          </Suspense>
        </AuthLayout>
      </Route>

      <Route path="/app">
        <PrivateLayout>
          <CabinetLayout>
            <UserAccess>
              <Suspense fallback={Fallback}>
                <Switch>
                  <Route path="/app/home" component={PrivateHomePage} />
                  <Route path="/app/about" component={AboutPage} />
                  <Route path="/app/support" component={SupportPage} />
                  <Route path="/app/contact" component={ContactPage} />
                  <Route path="/app/downloads" component={DownloadsPage} />
                  <Route path="/app/calendar/:item" component={CalendarPage} />
                  <Route path="/app/search/:search/:item" component={SearchPage} />
                  <Route path="/app/privacy-policy" component={PrivacyPolicyPage} />
                  <Route exact path="/app/products/:category/:productId" component={ProductPage} />
                  <Route exact path={['/app/products', '/app/products/:category']} component={ProductsPage} />
                  <Route exact path="/app/special-page/:id" component={SpecialPage} />
                  <Redirect to="/app/home" />
                </Switch>
              </Suspense>
            </UserAccess>
          </CabinetLayout>
        </PrivateLayout>
      </Route>
      <Redirect to="/app/home" />
    </Switch>
  </>
);

export default Routes;
