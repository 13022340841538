export const TYPES_COMPANY_INFO = {
  GET_COMPANY_INFO_REQUEST: 'GET_COMPANY_INFO_REQUEST',
  GET_COMPANY_INFO_SUCCESS: 'GET_COMPANY_INFO_SUCCESS',
  GET_COMPANY_INFO_ERROR: 'GET_COMPANY_INFO_ERROR',
};

const initialState = {
  loading: false,
  error: null,
  data: null,
};

const companyInfoReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TYPES_COMPANY_INFO.GET_COMPANY_INFO_REQUEST: {
      return { ...state, loading: true, error: null };
    }
    case TYPES_COMPANY_INFO.GET_COMPANY_INFO_SUCCESS: {
      return { ...state, loading: false, data: payload };
    }
    case TYPES_COMPANY_INFO.GET_COMPANY_INFO_ERROR: {
      return { ...state, loading: false, error: payload };
    }
    default:
      return state;
  }
};

export default companyInfoReducer;

export const requestCompanyInfo = () => ({
  type: TYPES_COMPANY_INFO.GET_COMPANY_INFO_REQUEST,
});

export const requestCompanyInfoSuccess = (data) => ({
  type: TYPES_COMPANY_INFO.GET_COMPANY_INFO_SUCCESS,
  payload: data,
});

export const requestCompanyInfoError = (err) => ({
  type: TYPES_COMPANY_INFO.GET_COMPANY_INFO_ERROR,
  payload: err,
});

export const SELECTORS = {
  getCompanyInfo: (state) => state.companyInfo.data,
};
