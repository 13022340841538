export const TIME_LINE_ITEMS = [
  {
    img: '../assets/images/comp.svg',
    time: '2004',
    text: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ',
  },
  {
    img: '',
    time: '2004',
    text:
      'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat ',
  },
  {
    img: 'test',
    time: '2009',
    type: true,
    text:
      'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat ',
  },
  {
    img: '',
    time: '2009',
    text:
      'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat ',
  },
  {
    img: '',
    time: '2011',
    text:
      'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat ',
  },
  {
    img: 'test',
    time: '2012',
    type: true,
    text:
      'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat ',
  },
  {
    img: '',
    time: '2012',
    text:
      'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat ',
  },
];

export const TABS_PRODUCTS = [
  {
    id: '1',
    label: 'All Products',
    products: [
      { id: 'product-1', label: 'all Item 1' },
      { id: 'product-2', label: 'all Item 2' },
      { id: 'product-3', label: 'all Item 3' },
      { id: 'product-4', label: 'all Item 4' },
      { id: 'product-5', label: 'all Item 5' },
      { id: 'product-6', label: 'all Item 6' },
      { id: 'product-7', label: 'all Item 7' },
      { id: 'product-8', label: 'all Item 8' },
      { id: 'product-9', label: 'all Item 9' },
      { id: 'product-10', label: 'all Item 10' },
      { id: 'product-11', label: 'all Item 11' },
      { id: 'product-12', label: 'all Item 12' },
      { id: 'product-13', label: 'all Item 13' },
      { id: 'product-14', label: 'all Item 14' },
    ],
  },
  {
    id: '2',
    label: 'One Category',
    products: [
      { id: 'product-1', label: 'one-item 1' },
      { id: 'product-2', label: 'one-item  2' },
      { id: 'product-3', label: 'one-item  3' },
      { id: 'product-4', label: 'one-item  4' },
      { id: 'product-5', label: 'one-item  5' },
      { id: 'product-6', label: 'one-item  6' },
      { id: 'product-7', label: 'one-item  7' },
      { id: 'product-8', label: 'one-item  8' },
      { id: 'product-9', label: 'one-item  9' },
      { id: 'product-10', label: 'one-item  10' },
      { id: 'product-11', label: 'one-item  11' },
      { id: 'product-12', label: 'one-item  12' },
      { id: 'product-13', label: 'one-item  13' },
      { id: 'product-14', label: 'one-item  14' },
    ],
  },
  {
    id: '3',
    label: 'Two Category',
    products: [
      { id: 'product-1', label: 'two-item 1' },
      { id: 'product-2', label: 'two-item  2' },
      { id: 'product-3', label: 'two-item  3' },
      { id: 'product-4', label: 'two-item  4' },
      { id: 'product-5', label: 'two-item  5' },
      { id: 'product-6', label: 'two-item  6' },
      { id: 'product-7', label: 'two-item  7' },
      { id: 'product-8', label: 'two-item  8' },
      { id: 'product-9', label: 'two-item  9' },
      { id: 'product-10', label: 'two-item  10' },
      { id: 'product-11', label: 'two-item  11' },
      { id: 'product-12', label: 'two-item  12' },
      { id: 'product-13', label: 'two-item  13' },
      { id: 'product-14', label: 'two-item  14' },
    ],
  },
];

export const CALENDAR_MENU = ['all', 'events', 'updates', 'products', 'recruitment'];

export const CALENDAR_SLIDER_DATA = [
  {
    id: 1,
    title: 'Latest calender item',
    time: 'Monday 1st January 2021',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ' +
      'Internal or external link. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  },
  {
    id: 2,
    title: 'Update Title',
    time: 'Tuesday 1st December 2020',
    text:
      'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat ' +
      'non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    id: 3,
    title: 'Recruitment update',
    time: 'Friday 1st December 2019',
    text:
      'Laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat ' +
      'nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    id: 4,
    title: 'Latest calender item',
    time: 'Monday 1st January 2021',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ' +
      'Ut enim ad minim veniam, internal or external link nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in ' +
      'voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia ' +
      'deserunt mollit anim id est laborum.',
  },
  {
    id: 5,
    title: 'Update Title',
    time: 'Friday 1st December 2019',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ' +
      'Ut enim ad minim veniam, internal or external link nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in ' +
      'voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia ' +
      'deserunt mollit anim id est laborum.',
  },
  {
    id: 6,
    title: 'Recruitment update',
    time: 'Tuesday 1st December 2020',
    text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ' +
      'Ut enim ad minim veniam, internal or external link nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in ' +
      'voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia ' +
      'deserunt mollit anim id est laborum.',
  },
];

export const SEARCH_MENU = ['all', 'downloads', 'products', 'events', 'updates', 'recruitment'];

export const USER_ROLES = Object.freeze({
  LEVEL_2: 'level_2',
  LEVEL_3: 'level_3',
  LEVEL_4: 'level_4',
});
