export const TYPES_MEDIA_RICH = {
  GET_MEDIA_RICH_REQUEST: 'GET_MEDIA_RICH_REQUEST',
  GET_MEDIA_RICH_SUCCESS: 'GET_MEDIA_RICH_SUCCESS',
  GET_MEDIA_RICH_ERROR: 'GET_MEDIA_RICH_ERROR',
};

const initialState = {
  loading: false,
  error: null,
  data: null,
};

const mediaRichReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TYPES_MEDIA_RICH.GET_MEDIA_RICH_REQUEST: {
      return { ...state, loading: true, error: null };
    }
    case TYPES_MEDIA_RICH.GET_MEDIA_RICH_SUCCESS: {
      return { ...state, loading: false, data: payload };
    }
    case TYPES_MEDIA_RICH.GET_MEDIA_RICH_ERROR: {
      return { ...state, loading: false, error: payload };
    }
    default:
      return state;
  }
};

export default mediaRichReducer;

export const requestMediaRich = () => ({
  type: TYPES_MEDIA_RICH.GET_MEDIA_RICH_REQUEST,
});

export const requestMediaRichSuccess = (data) => ({
  type: TYPES_MEDIA_RICH.GET_MEDIA_RICH_SUCCESS,
  payload: data,
});

export const requestMediaRichError = (err) => ({
  type: TYPES_MEDIA_RICH.GET_MEDIA_RICH_ERROR,
  payload: err,
});

export const SELECTORS = {
  getLoading: (state) => state.mediaRich.loading,
  getError: (state) => state.mediaRich.error,
  getMediaRich: (state) => state.mediaRich.data,
};
