import { takeEvery, put, fork } from 'redux-saga/effects';

import API from 'resources/api';
import { DOWNLOAD_FORM } from 'resources/schema';
import { TYPES_DOWNLOAD_FORM, downloadFormSuccess, downloadFormError } from 'store/ducks/downloadForm';

// POST DOWNLOAD FORM
function* watchDownloadFormData() {
  yield takeEvery(TYPES_DOWNLOAD_FORM.DOWNLOAD_REQUEST_FORM, handleDownloadForm);
}

function* handleDownloadForm({ payload }) {
  try {
    const formData = yield API.post(DOWNLOAD_FORM, payload);
    yield put(downloadFormSuccess(formData.data.status));
  } catch (e) {
    yield put(downloadFormError(e.message));
  }
}

export default function* downloadFormSaga() {
  yield fork(watchDownloadFormData);
}
