export const TYPES_PUBLIC_FOOTER = {
  GET_PUBLIC_FOOTER_REQUEST: 'GET_PUBLIC_FOOTER_REQUEST',
  GET_PUBLIC_FOOTER_SUCCESS: 'GET_PUBLIC_FOOTER_SUCCESS',
  GET_PUBLIC_FOOTER_ERROR: 'GET_PUBLIC_FOOTER_ERROR',
};

const initialState = {
  loading: false,
  error: null,
  data: null,
};

const publicFooterReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TYPES_PUBLIC_FOOTER.GET_PUBLIC_FOOTER_REQUEST: {
      return { ...state, loading: true, error: null };
    }
    case TYPES_PUBLIC_FOOTER.GET_PUBLIC_FOOTER_SUCCESS: {
      return { ...state, loading: false, data: payload };
    }
    case TYPES_PUBLIC_FOOTER.GET_PUBLIC_FOOTER_ERROR: {
      return { ...state, loading: false, error: payload };
    }
    default:
      return state;
  }
};

export default publicFooterReducer;

export const requestPublicFooter = () => ({
  type: TYPES_PUBLIC_FOOTER.GET_PUBLIC_FOOTER_REQUEST,
});

export const requestPublicFooterSuccess = (data) => ({
  type: TYPES_PUBLIC_FOOTER.GET_PUBLIC_FOOTER_SUCCESS,
  payload: data,
});

export const requestPublicFooterError = (err) => ({
  type: TYPES_PUBLIC_FOOTER.GET_PUBLIC_FOOTER_ERROR,
  payload: err,
});

export const SELECTORS = {
  getPublicFooter: (state) => state.publicFooter.data,
};
