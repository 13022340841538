export const TYPES_PRODUCTS = {
  PRODUCTS_LIST_REQUEST: 'PRODUCTS_LIST_REQUEST',
  PRODUCTS_LIST_SUCCESS: 'PRODUCTS_LIST_SUCCESS',
  PRODUCTS_LIST_ERROR: 'PRODUCTS_LIST_ERROR',

  PRODUCTS_CATEGORIES_LIST_REQUEST: 'PRODUCTS_CATEGORIES_LIST_REQUEST',

  PRODUCTS_RELATED_LIST_REQUEST: 'PRODUCTS_RELATED_LIST_REQUEST',

  PRODUCTS_LIST_LOAD_MORE_REQUEST: 'PRODUCTS_LIST_LOAD_MORE_REQUEST',
  PRODUCTS_LIST_LOAD_MORE_SUCCESS: 'PRODUCTS_LIST_LOAD_MORE_SUCCESS',
  PRODUCTS_LIST_LOAD_MORE_ERROR: 'PRODUCTS_LIST_LOAD_MORE_ERROR',

  PRODUCT_REQUEST: 'PRODUCT_REQUEST',
  PRODUCT_SUCCESS: 'PRODUCT_SUCCESS',
  PRODUCT_ERROR: 'PRODUCT_ERROR',

  PRODUCT_FILES_REQUEST: 'PRODUCT_FILES_REQUEST',
  PRODUCT_FILES_SUCCESS: 'PRODUCT_FILES_SUCCESS',
  PRODUCT_FILES_ERROR: 'PRODUCT_FILES_ERROR',

  CATEGORY_REQUEST: 'CATEGORY_REQUEST',
  CATEGORY_SUCCESS: 'CATEGORY_SUCCESS',
  CATEGORY_ERROR: 'CATEGORY_ERROR',
};

const initialValues = {
  loadingList: true,
  loadingMore: false,
  dataList: {
    items: [],
    count: null,
    offset: null,
    limit: null,
  },
  dataListErrorInfo: null,
  dataListFailed: false,
  offset: 0,
  limit: false,
  categoryId: false,

  loadingProduct: true,
  dataProduct: null,
  dataProductError: null,
  dataProductFailed: false,

  loadingFiles: true,
  dataFiles: null,
  dataFilesError: null,
  dataFilesFailed: false,

  loadingCategories: true,
  dataCategories: null,
  dataCategoriesErrorInfo: null,
  dataCategoriesFailed: false,
};

const products = (state = initialValues, { type, payload }) => {
  switch (type) {
    case TYPES_PRODUCTS.PRODUCTS_LIST_REQUEST:
      return { ...state, limit: false, categoryId: false };
    case TYPES_PRODUCTS.PRODUCTS_LIST_SUCCESS:
      return {
        ...state,
        loadingList: false,
        dataList: {
          items: payload.items,
          count: payload.count,
          offset: payload.offset,
          limit: payload.limit,
        },
        offset: 0,
      };
    case TYPES_PRODUCTS.PRODUCTS_LIST_ERROR:
      return { ...state, loadingList: false, dataListErrorInfo: payload, dataListFailed: true };

    case TYPES_PRODUCTS.PRODUCTS_RELATED_LIST_REQUEST:
      return { ...state, limit: 3, categoryId: false };

    case TYPES_PRODUCTS.PRODUCTS_CATEGORIES_LIST_REQUEST:
      return { ...state, loadingList: true, categoryId: payload };

    case TYPES_PRODUCTS.PRODUCTS_LIST_LOAD_MORE_REQUEST:
      return { ...state, loadingMore: true, offset: state.offset + 12 };
    case TYPES_PRODUCTS.PRODUCTS_LIST_LOAD_MORE_SUCCESS:
      return {
        ...state,
        loadingMore: false,
        dataList: {
          items: [...state.dataList.items, ...payload.data.items],
          count: payload.data.count,
          offset: payload.data.offset,
          limit: payload.data.limit,
        },
      };
    case TYPES_PRODUCTS.PRODUCTS_LIST_LOAD_MORE_ERROR:
      return { ...state, loadingMore: false, dataListErrorInfo: payload, dataCategoriesFailed: true };

    case TYPES_PRODUCTS.PRODUCT_REQUEST:
      return { ...state, loadingProduct: true };
    case TYPES_PRODUCTS.PRODUCT_SUCCESS:
      return { ...state, loadingProduct: false, dataProduct: payload };
    case TYPES_PRODUCTS.PRODUCT_ERROR:
      return { ...state, loadingProduct: false, dataProductFailed: true, dataProductError: payload };

    case TYPES_PRODUCTS.PRODUCT_FILES_REQUEST:
      return { ...state };
    case TYPES_PRODUCTS.PRODUCT_FILES_SUCCESS:
      return { ...state, loadingFiles: false, dataFiles: payload };
    case TYPES_PRODUCTS.PRODUCT_FILES_ERROR:
      return { ...state, loadingFiles: false, dataProductFailed: true, dataFilesError: payload };

    case TYPES_PRODUCTS.CATEGORY_REQUEST:
      return { ...state };
    case TYPES_PRODUCTS.CATEGORY_SUCCESS:
      return { ...state, loadingCategories: false, dataCategories: payload };
    case TYPES_PRODUCTS.CATEGORY_ERROR:
      return { ...state, loadingCategories: false, dataCategoriesErrorInfo: payload, dataCategoriesFailed: true };

    default:
      return state;
  }
};

export default products;

// GET PRODUCTS LIST
export const productsListRequest = (related) => ({
  type: TYPES_PRODUCTS.PRODUCTS_LIST_REQUEST,
  payload: related,
});

export const productsRelatedListRequest = ({ relatedLimit, categoryId }) => ({
  type: TYPES_PRODUCTS.PRODUCTS_RELATED_LIST_REQUEST,
  payload: { relatedLimit, categoryId },
});

export const productsCategoriesListRequest = (id) => ({
  type: TYPES_PRODUCTS.PRODUCTS_CATEGORIES_LIST_REQUEST,
  payload: id,
});

export const productsListSuccess = (data) => ({
  type: TYPES_PRODUCTS.PRODUCTS_LIST_SUCCESS,
  payload: data,
});

export const productsListError = (err) => ({
  type: TYPES_PRODUCTS.PRODUCTS_LIST_ERROR,
  payload: err,
});

export const productsListLoadMoreRequest = () => ({
  type: TYPES_PRODUCTS.PRODUCTS_LIST_LOAD_MORE_REQUEST,
});

export const productsListLoadMoreSuccess = (data) => ({
  type: TYPES_PRODUCTS.PRODUCTS_LIST_LOAD_MORE_SUCCESS,
  payload: data,
});

export const productsLoadMoreListError = (err) => ({
  type: TYPES_PRODUCTS.PRODUCTS_LIST_LOAD_MORE_ERROR,
  payload: err,
});

// GET PRODUCT
export const productRequest = (id) => ({
  type: TYPES_PRODUCTS.PRODUCT_REQUEST,
  payload: id,
});

export const productSuccess = (data) => ({
  type: TYPES_PRODUCTS.PRODUCT_SUCCESS,
  payload: data,
});

export const productError = (err) => ({
  type: TYPES_PRODUCTS.PRODUCT_ERROR,
  payload: err,
});

// GET PRODUCT FILES
export const productFilesRequest = (id) => ({
  type: TYPES_PRODUCTS.PRODUCT_FILES_REQUEST,
  payload: id,
});

export const productFilesSuccess = (data) => ({
  type: TYPES_PRODUCTS.PRODUCT_FILES_SUCCESS,
  payload: data,
});

export const productFilesError = (err) => ({
  type: TYPES_PRODUCTS.PRODUCT_FILES_ERROR,
  payload: err,
});

// GET CATEGORIES
export const categoriesRequest = () => ({
  type: TYPES_PRODUCTS.CATEGORY_REQUEST,
});

export const categoriesSuccess = (data) => ({
  type: TYPES_PRODUCTS.CATEGORY_SUCCESS,
  payload: data,
});

export const categoriesError = (err) => ({
  type: TYPES_PRODUCTS.CATEGORY_ERROR,
  payload: err,
});

export const PRODUCTS_SELECTORS = {
  getList: (state) => state.products,
  getProduct: (state) => state.products,
  getProductFiles: (state) => state.products,
  getCategories: (state) => state.products,
};
