import { takeEvery, put, fork, select } from 'redux-saga/effects';

import API from 'resources/api';
import { GET_PRODUCTS_LIST, GET_PRODUCT, GET_PRODUCT_FILES, GET_CATEGORIES } from 'resources/schema';
import {
  TYPES_PRODUCTS,
  productsListSuccess,
  productsListError,
  productSuccess,
  productError,
  productFilesSuccess,
  productFilesError,
  categoriesSuccess,
  categoriesError,
  productsListLoadMoreSuccess,
  productsLoadMoreListError,
} from 'store/ducks/products';

function* watchProductsData() {
  yield takeEvery(TYPES_PRODUCTS.PRODUCTS_LIST_REQUEST, handleProductsList);
  yield takeEvery(TYPES_PRODUCTS.PRODUCT_REQUEST, handleProduct);
  yield takeEvery(TYPES_PRODUCTS.PRODUCT_FILES_REQUEST, handleProductFiles);
  yield takeEvery(TYPES_PRODUCTS.CATEGORY_REQUEST, handleCategories);
  yield takeEvery(TYPES_PRODUCTS.PRODUCTS_RELATED_LIST_REQUEST, handleProductsList);
  yield takeEvery(TYPES_PRODUCTS.PRODUCTS_LIST_LOAD_MORE_REQUEST, handleLoadMoreProductsList);
  yield takeEvery(TYPES_PRODUCTS.PRODUCTS_CATEGORIES_LIST_REQUEST, handleProductsList);
}

function* handleProductsList({ payload }) {
  try {
    const { limit, categoryId } = yield select((state) => state.products);
    const productsData = yield API.get(
      GET_PRODUCTS_LIST({ limit: payload?.relatedLimit || limit, categoryId: payload?.categoryId || categoryId })
    );
    yield put(productsListSuccess(productsData.data.data));
  } catch (e) {
    yield put(productsListError(e));
  }
}

function* handleLoadMoreProductsList() {
  try {
    const { offset, categoryId } = yield select((state) => state.products);
    const data = yield API.get(GET_PRODUCTS_LIST({ offset, categoryId }));
    yield put(productsListLoadMoreSuccess(data.data));
  } catch (e) {
    yield put(productsLoadMoreListError(e.response));
  }
}

function* handleProduct({ payload }) {
  try {
    const productData = yield API.get(GET_PRODUCT(payload));
    yield put(productSuccess(productData.data.data));
  } catch (e) {
    yield put(productError(e.response));
  }
}

function* handleProductFiles({ payload }) {
  try {
    const productFilesData = yield API.get(GET_PRODUCT_FILES(payload));
    yield put(productFilesSuccess(productFilesData.data.data));
  } catch (e) {
    yield put(productFilesError(e.response));
  }
}

function* handleCategories() {
  try {
    const categoriesData = yield API.get(GET_CATEGORIES);
    yield put(categoriesSuccess(categoriesData.data.data));
  } catch (e) {
    yield put(categoriesError(e.response));
  }
}

export default function* productsSagas() {
  yield fork(watchProductsData);
}
