import React, { memo } from 'react';

const CloseIcon = memo(({ darkMode, color }) => (
  <svg width="20" height="20" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.7132 1.60169C20.0956 1.23601 20.0956 0.640567 19.7132 0.274529C19.3305 -0.0915095 18.7079 -0.0915095 18.3251 0.274529L10.0012
      8.23568L1.67532 0.274529C1.29258 -0.0915095 0.669978 -0.09115 0.287617 0.274529C0.102264 0.451795 0 0.68767 0 0.938287C0 1.1889 0.102264
      1.42478 0.287617 1.60169L8.54843 9.50243L0.292129 17.3967C-0.0891049 17.7638 -0.0887289 18.3596 0.291753 18.7231C0.659827 19.0795 1.31439
      19.0795 1.68134 18.7246L9.99967 10.7692L18.3176 18.7231C18.5022 18.9018 18.7489 19 19.0124 19C19.2752 19 19.5219 18.9026 19.7072
      18.7246C20.0892 18.3596 20.0896 17.7638 19.7072 17.396L11.4535 9.50243L19.7132 1.60169Z"
      fill={darkMode ? 'var(--white)' : color || '#4a4f54'}
    />
  </svg>
));

export default CloseIcon;
