export const TYPES_CALENDAR_TIMELINES = {
  GET_TIMELINES_REQUEST: 'CALENDAR/GET_TIMELINES_REQUEST',
  GET_TIMELINES_SUCCESS: 'CALENDAR/GET_TIMELINES_SUCCESS',
  GET_TIMELINES_ERROR: 'CALENDAR/GET_TIMELINES_ERROR',
  SET_CATEGORY_ID: 'CALENDAR/SET_CATEGORY_ID',
  SET_TIMELINES: 'CALENDAR/SET_TIMELINES',
  SET_OFFSET: 'CALENDAR/SET_OFFSET',
  SET_LIMIT: 'CALENDAR/SET_LIMIT',
};

const initialState = {
  loading: false,
  error: false,
  categoryId: 0,
  limit: 0,
  offset: 0,
  hasMorePages: false,
  timelines: [],
};

const calendarReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TYPES_CALENDAR_TIMELINES.GET_TIMELINES_REQUEST: {
      return { ...state, loading: true, error: false };
    }
    case TYPES_CALENDAR_TIMELINES.GET_TIMELINES_SUCCESS: {
      const nextStateBase = { ...state, loading: false, hasMorePages: payload.has_more_pages };

      if (payload.timelineId) {
        return { ...nextStateBase, hasMorePages: false, timelines: [payload.timelines[0]] };
      }

      return payload.loadMore
        ? { ...nextStateBase, timelines: [...state.timelines, ...payload.timelines] }
        : { ...nextStateBase, timelines: payload.timelines };
    }
    case TYPES_CALENDAR_TIMELINES.GET_TIMELINES_ERROR: {
      return { ...state, loading: false, error: true };
    }
    case TYPES_CALENDAR_TIMELINES.SET_CATEGORY_ID: {
      return { ...state, categoryId: payload };
    }
    case TYPES_CALENDAR_TIMELINES.SET_TIMELINES: {
      return { ...state, timelines: payload };
    }
    case TYPES_CALENDAR_TIMELINES.SET_OFFSET: {
      return { ...state, offset: payload };
    }
    case TYPES_CALENDAR_TIMELINES.SET_LIMIT: {
      return { ...state, limit: payload };
    }
    default:
      return state;
  }
};

export default calendarReducer;

export const requestCalendarTimelines = ({ payload }) => ({
  type: TYPES_CALENDAR_TIMELINES.GET_TIMELINES_REQUEST,
  payload,
});

export const requestCalendarTimelinesSuccess = (data) => ({
  type: TYPES_CALENDAR_TIMELINES.GET_TIMELINES_SUCCESS,
  payload: data,
});

export const requestCalendarTimelinesError = (err) => ({
  type: TYPES_CALENDAR_TIMELINES.GET_TIMELINES_ERROR,
  payload: err,
});

export const setCategoryId = (data) => ({
  type: TYPES_CALENDAR_TIMELINES.SET_CATEGORY_ID,
  payload: data,
});

export const setTimelines = (data) => ({
  type: TYPES_CALENDAR_TIMELINES.SET_TIMELINES,
  payload: data,
});

export const setOffset = (data) => ({
  type: TYPES_CALENDAR_TIMELINES.SET_OFFSET,
  payload: data,
});

export const setLimit = (data) => ({
  type: TYPES_CALENDAR_TIMELINES.SET_LIMIT,
  payload: data,
});

export const SELECTORS = {
  getLoading: (state) => state.calendar.loading,
  getError: (state) => state.calendar.error,
  getCategoryId: (state) => state.calendar.categoryId,
  getLimit: (state) => state.calendar.limit,
  getOffset: (state) => state.calendar.offset,
  getHasMorePages: (state) => state.calendar.hasMorePages,
  getTimelines: (state) => state.calendar.timelines,
};
