export const TYPES_PUBLIC_PAGE = {
  GET_PUBLIC_PAGE_REQUEST: 'GET_PUBLIC_PAGE_REQUEST',
  GET_PUBLIC_PAGE_SUCCESS: 'GET_PUBLIC_PAGE_SUCCESS',
  GET_PUBLIC_PAGE_ERROR: 'GET_PUBLIC_PAGE_ERROR',
};

const initialState = {
  loading: false,
  error: null,
  data: null,
};

const publicPageReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TYPES_PUBLIC_PAGE.GET_PUBLIC_PAGE_REQUEST: {
      return { ...state, loading: true, error: null };
    }
    case TYPES_PUBLIC_PAGE.GET_PUBLIC_PAGE_SUCCESS: {
      return { ...state, loading: false, data: payload };
    }
    case TYPES_PUBLIC_PAGE.GET_PUBLIC_PAGE_ERROR: {
      return { ...state, loading: false, error: payload };
    }
    default:
      return state;
  }
};

export default publicPageReducer;

export const requestPublicPage = () => ({
  type: TYPES_PUBLIC_PAGE.GET_PUBLIC_PAGE_REQUEST,
});

export const requestPublicPageSuccess = (data) => ({
  type: TYPES_PUBLIC_PAGE.GET_PUBLIC_PAGE_SUCCESS,
  payload: data,
});

export const requestPublicPageError = (err) => ({
  type: TYPES_PUBLIC_PAGE.GET_PUBLIC_PAGE_ERROR,
  payload: err,
});

export const SELECTORS = {
  getPublicPage: (state) => state.public.data,
};
