export const en = {
  HEADER: {
    home: 'Home',
    about: 'About',
    products: 'Products',
    support: 'Support',
    downloads: 'Downloads',
    calendar: 'Calendar',
    contact: 'Contact',
    allProducts: 'All Products',
    logout: 'Logout',
    call: 'call our sales team:',
  },
};
