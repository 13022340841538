import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { requestPublicFooter, SELECTORS as FOOTER_INFO_SELECTORS } from 'store/ducks/publicFooterInfo';

import { ReactComponent as LogoX } from 'assets/icons/logoX.svg';

import './PublicFooter.scss';

const PublicFooter = () => {
  const [userIP, setUserIP] = useState('xx.xx.xxx.xx');
  const footerInfo = useSelector(FOOTER_INFO_SELECTORS.getPublicFooter);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestPublicFooter());
    const ip = localStorage.getItem('ip');
    if (ip) {
      setUserIP(ip);
    }
  }, []);

  return (
    <footer className="publicFooter">
      <div className="publicInfoBlock">
        <div className="companyInfo">
          <div>{footerInfo?.company_name}</div>
          <div>{footerInfo?.address}</div>
          <div>{`${footerInfo?.postcode}, United Kingdom`}</div>
        </div>
        <div className="privacyInfo">
          <div className="text">
            {`Your IP address, [${userIP}], has been recorded and all activity on this system is actively monitored. Under
            US Federal Law (18 U.S.C. 1030), United Kingdom Law (Computer Misuse Act 1990) and other international law it is
            a criminal offence to access or attempt to access this computer system without prior written authorisation from
            ${footerInfo?.company_name}. Any unauthorised attempt to access this system will be reported to the appropriate authorities and
            prosecuted to the full extent of the law.`}
          </div>
          <Link className="privacy-link" to="/auth/public-privacy-policy">
            Privacy Policy
          </Link>
        </div>
      </div>
      <div className="iconBlock">
        <LogoX />
        <hr />
      </div>
    </footer>
  );
};

export default PublicFooter;
