import { takeEvery, put, fork } from 'redux-saga/effects';

import API from 'resources/api';
import { GET_SPECIAL_LIST, GET_SPECIAL_PAGE, GET_SPECIAL_PAGE_FILES } from 'resources/schema';
import {
  TYPES_SPECIAL,
  specialListSuccess,
  specialListError,
  specialPageSuccess,
  specialPageError,
  specialPageFilesSuccess,
  specialPageFilesError,
} from 'store/ducks/special';

function* watchSpecialData() {
  yield takeEvery(TYPES_SPECIAL.SPECIAL_LIST_REQUEST, handleSpecialList);
  yield takeEvery(TYPES_SPECIAL.SPECIAL_PAGE_REQUEST, handleSpecialPage);
  yield takeEvery(TYPES_SPECIAL.SPECIAL_PAGE_FILES_REQUEST, handleSpecialPageFiles);
}

function* handleSpecialList() {
  try {
    const specialList = yield API.get(GET_SPECIAL_LIST);
    yield put(specialListSuccess(specialList.data));
  } catch (e) {
    yield put(specialListError(e.response));
  }
}

function* handleSpecialPage({ payload }) {
  try {
    const specialPage = yield API.get(`${GET_SPECIAL_PAGE}/${payload}`);
    yield put(specialPageSuccess(specialPage.data.data));
  } catch (e) {
    yield put(specialPageError(e.response));
  }
}

function* handleSpecialPageFiles({ payload }) {
  try {
    const specialPageFiles = yield API.get(GET_SPECIAL_PAGE_FILES(payload));
    yield put(specialPageFilesSuccess(specialPageFiles.data.data));
  } catch (e) {
    yield put(specialPageFilesError(e.response));
  }
}

export default function* specialSagas() {
  yield fork(watchSpecialData);
}
