import { useEffect, useState } from 'react';

const useDynamicViewportSize = () => {
  const [vh, setVh] = useState(null);
  const [vw, setVw] = useState(null);

  const handleResize = () => {
    setVh(window.innerHeight);
    setVw(window.innerWidth);
  };

  useEffect(() => {
    setVh(window.innerHeight);
    setVw(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { vh, vw };
};

export default useDynamicViewportSize;
