import { takeEvery, put, fork } from 'redux-saga/effects';

import API from 'resources/api';
import { GET_MEDIA_RICH } from 'resources/schema';
import { TYPES_MEDIA_RICH, requestMediaRichSuccess, requestMediaRichError } from 'store/ducks/mediaRich';

function* watchMediaRichData() {
  yield takeEvery(TYPES_MEDIA_RICH.GET_MEDIA_RICH_REQUEST, getMediaRich);
}

function* getMediaRich() {
  try {
    const mediaRich = yield API.get(GET_MEDIA_RICH);
    yield put(requestMediaRichSuccess(mediaRich.data.data));
  } catch (err) {
    yield put(requestMediaRichError(err));
  }
}

export default function* mediaRichSaga() {
  yield fork(watchMediaRichData);
}
