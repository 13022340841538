import { takeEvery, put, fork } from 'redux-saga/effects';

import API from 'resources/api';
import { GET_COMPANY_INFO } from 'resources/schema';
import { TYPES_COMPANY_INFO, requestCompanyInfoSuccess, requestCompanyInfoError } from 'store/ducks/companyInfo';

function* watchCompanyInfoData() {
  yield takeEvery(TYPES_COMPANY_INFO.GET_COMPANY_INFO_REQUEST, getCompanyInfo);
}

function* getCompanyInfo() {
  try {
    const companyInfo = yield API.get(GET_COMPANY_INFO);
    yield put(requestCompanyInfoSuccess({ ...companyInfo.data.data }));
  } catch (err) {
    yield put(requestCompanyInfoError(err));
  }
}

export default function* companyInfoSaga() {
  yield fork(watchCompanyInfoData);
}
