export const TOKEN_STORAGE_KEY = 'token';

export const TYPES_AUTH = {
  AUTH_REQUEST: 'AUTH_REQUEST',
  AUTH_SET_FAIL: 'AUTH_SET_FAIL',
  AUTH_SUCCESS: 'AUTH_SUCCESS',
  AUTH_ERROR: 'AUTH_ERROR',
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_ERROR: 'LOGOUT_ERROR',
};

const token = localStorage.getItem(TOKEN_STORAGE_KEY);

const initialState = {
  loading: true,
  token: token || null,
  currentUser: null,
  errorInfo: null,
  failed: false,
  logoutSuccess: false,
};

const auth = (state = initialState, { type, payload }) => {
  switch (type) {
    case TYPES_AUTH.AUTH_REQUEST:
      return { ...state };
    case TYPES_AUTH.AUTH_SET_FAIL:
      return { ...state, loading: false, failed: true };
    case TYPES_AUTH.AUTH_SUCCESS:
      return { ...state, loading: false, currentUser: payload };
    case TYPES_AUTH.AUTH_ERROR:
    case TYPES_AUTH.LOGOUT_ERROR:
      return { ...state, loading: false, errorInfo: payload, failed: true };
    case TYPES_AUTH.LOGOUT_REQUEST:
      return { ...state, loading: true };
    case TYPES_AUTH.LOGOUT_SUCCESS:
      return { ...initialState, loading: false, token: null, logoutSuccess: true };
    default:
      return state;
  }
};

export default auth;

export const authRequest = () => ({
  type: TYPES_AUTH.AUTH_REQUEST,
});

export const authSetFail = () => ({
  type: TYPES_AUTH.AUTH_SET_FAIL,
});

export const authSuccess = (userData) => ({
  type: TYPES_AUTH.AUTH_SUCCESS,
  payload: userData,
});

export const authErr = (err) => ({
  type: TYPES_AUTH.AUTH_ERROR,
  payload: err,
});

export const logoutRequest = () => ({
  type: TYPES_AUTH.LOGOUT_REQUEST,
});

export const logoutSuccess = () => ({
  type: TYPES_AUTH.LOGOUT_SUCCESS,
});

export const logoutErr = (err) => ({
  type: TYPES_AUTH.LOGOUT_ERROR,
  payload: err,
});

export const AUTH_SELECTOR = {
  getAuth: (state) => state.auth,
};
