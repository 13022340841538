import { useSelector } from 'react-redux';

import { AUTH_SELECTOR } from 'store/ducks/auth';

const getRoleNumber = (role) => role?.split('_')[1];

const Access = ({ children, accessRole, exact }) => {
  const { currentUser } = useSelector(AUTH_SELECTOR.getAuth);

  const userLevelNumber = getRoleNumber(currentUser?.data?.role);
  const accessLevelNumber = getRoleNumber(accessRole);

  if (exact && userLevelNumber === accessLevelNumber) {
    return children;
  }

  if (!exact && userLevelNumber <= accessLevelNumber) {
    return children;
  }

  return null;
};

export default Access;
