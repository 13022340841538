export const de = {
  HEADER: {
    home: 'Thuis',
    about: 'Over',
    products: 'Producten',
    support: 'Steun',
    downloads: 'Downloads',
    calendar: 'Kalender',
    contact: 'Contact',
    allProducts: 'Alle producten',
    logout: 'uitloggen',
    call: 'bel ons verkoopteam:',
  },
};
