import React, { memo, useEffect, useState, useRef, Fragment } from 'react';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { requestCompanyInfo, SELECTORS as COMPANY_INFO_SELECTORS } from 'store/ducks/companyInfo';
import { USER_ROLES } from 'helpers/arrays';
import Button from 'components/Button';
import Search from 'containers/forms/Search';
import CloseIcon from 'components/Icons/CloseIcon';
import MiniArrowTopDown from 'components/Icons/ArrowMiniTopBottom';
import ArrowRight from 'components/Icons/ArrowRight';
import ArrowSide from 'components/Icons/ArrowSide';
import Access from 'components/Access';
import { SearchSchemaValidation } from 'helpers/validators';

import useDynamicViewportSize from 'hooks/useDynamicViewportSize';
import useOutsideClick from 'hooks/useOutsideClick';
import { logoutRequest, AUTH_SELECTOR } from 'store/ducks/auth';
import { headerProductsRequest, headerCategoriesRequest, HEADER_SELECTORS } from 'store/ducks/headerProducts';

import { ReactComponent as MiniLogoX } from 'assets/icons/logoMiniX.svg';
import { ReactComponent as USAFlag } from 'assets/icons/flags/USAflag.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/arrowDowm.svg';
import { ReactComponent as Call } from 'assets/icons/phoneCall.svg';
import { ReactComponent as Burger } from 'assets/icons/burger.svg';
import HeaderImg from 'assets/images/headerPlanet.png';
import HeaderImgDark from 'assets/images/headerPlanetDark.png';
import UKFlag from 'assets/icons/flags/UKflag.svg';
import ItalyFlag from 'assets/icons/flags/italyFlag.svg';
import FranceFlag from 'assets/icons/flags/franceFlag.svg';
import SpainFlag from 'assets/icons/flags/spainFlag.svg';
import GermanyFlag from 'assets/icons/flags/germanyFlag.svg';
import PolandFlag from 'assets/icons/flags/polandFlag.svg';
import { SELECTORS as IMAGES_SELECTORS } from 'store/ducks/images';
import Comp from 'assets/images/comp.svg';

import './Header.scss';

const languages = [
  { label: 'EN', icon: UKFlag, value: 'en' },
  { label: 'IT', icon: ItalyFlag, value: 'it' },
  { label: 'FR', icon: FranceFlag, value: 'fr' },
  { label: 'ES', icon: SpainFlag, value: 'es' },
  { label: 'DE', icon: GermanyFlag, value: 'de' },
  { label: 'PL', icon: PolandFlag, value: 'pl' },
];

const Header = memo(({ darkMode }) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [t, i18n] = useTranslation();
  const { vw } = useDynamicViewportSize();

  const [openLang, setOpenLang] = useState(false);
  const [selectedLang, setSelectedLang] = useState({ label: 'EN', icon: UKFlag });
  const [openSearch, setOpenSearch] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);

  const menuHeaderRef = useRef(null);
  const burgerButtonRef = useRef(null);
  const langMenuRef = useRef(null);
  const searchFormRef = useRef(null);

  const { currentUser } = useSelector(AUTH_SELECTOR.getAuth);
  const companyInfo = useSelector(COMPANY_INFO_SELECTORS.getCompanyInfo);
  const { productsData, categoriesData, hiddenProducts } = useSelector(HEADER_SELECTORS.getHeaderData);
  const images = useSelector(IMAGES_SELECTORS.getImages);

  const setInitLanguage = async (value) => {
    const languageDictionary = languages.find((e) => e.value === value);
    setSelectedLang({ label: languageDictionary.label, icon: languageDictionary.icon });
    await i18n.changeLanguage(languageDictionary.value);
  };

  useEffect(() => {
    dispatch(headerProductsRequest());
    dispatch(headerCategoriesRequest());
    setActiveCategory(0);
    dispatch(requestCompanyInfo());
    const languagePreference = localStorage.getItem('lng');
    if (languagePreference) {
      setInitLanguage(languagePreference);
    }
  }, []);

  const handleCloseMenu = () => {
    setOpenDropdown(false);
  };

  const handleCloseLang = () => {
    setOpenLang(false);
  };

  useOutsideClick({ containerRefs: [menuHeaderRef, burgerButtonRef], onClose: handleCloseMenu });
  useOutsideClick({ containerRefs: [langMenuRef], onClose: handleCloseLang });
  useOutsideClick({ containerRefs: [searchFormRef], onClose: () => setOpenSearch(false) });

  const handleSelectedLang = async (label, icon, value) => {
    setSelectedLang({ label, icon });
    await i18n.changeLanguage(value);
    setOpenLang(false);
    document.location.reload();
  };

  const handleSearch = (value) => {
    if (!value.search) return;
    setOpenDropdown(false);
    history.push(`/app/search/${value.search}/all`);
  };

  const handleMenu = () => {
    setOpenDropdown((prev) => !prev);
    setOpenCategory(false);
    setActiveCategory(null);
  };

  const handleLogout = () => {
    dispatch(logoutRequest());
  };

  const handleOpenCategory = () => {
    setOpenCategory((prev) => !prev);
    setActiveCategory(null);
  };

  const handleOpenProducts = (id) => {
    if (activeCategory === id) {
      setActiveCategory(null);
      return;
    }

    setActiveCategory(id);
    dispatch(headerProductsRequest(id));
  };

  const handleCategory = (id) => () => {
    setActiveCategory(id);
    dispatch(headerProductsRequest(id));
  };

  useIdleTimer({
    timeout: 1000 * 60 * 15, // 15 min timeout
    onIdle: handleLogout,
    debounce: 500,
  });

  const getLogo = (ver) => {
    if (ver === 1) {
      return darkMode ? companyInfo?.first_black_logo : companyInfo?.first_logo;
    }
    return darkMode ? companyInfo?.second_black_logo : companyInfo?.second_logo;
  };

  return (
    <header className="header">
      <div className="topHeader">
        <div className="topHeaderContainer">
          <div className="leftBlock">
            <MiniLogoX className="miniLogo" />

            <div className="item">{t('HEADER.call')}</div>

            <div className="item">
              <img src={UKFlag} className="iconFlag" alt="UK" />
              <span>{companyInfo?.header?.phone_number_first}</span>
            </div>

            <div className="item">
              <USAFlag className="iconFlag" />
              <span>{companyInfo?.header?.phone_number_second}</span>
            </div>
          </div>

          <div className="rightBlock">
            <a href={`tel:${companyInfo?.header?.phone_number_first}`}>
              <Call className="callIcon" />
            </a>

            <div ref={langMenuRef} className="langBlock" style={{ display: 'none' }}>
              <Button
                variant="ghost"
                onClick={() => setOpenLang((prevState) => !prevState)}
                className={classNames('langButton', { selectedClr: openLang })}
              >
                <img src={selectedLang.icon} alt="flag" />
                <span className="lang">{selectedLang.label}</span>
                <ArrowDown />
              </Button>

              <div className={classNames('selectLang', { openSelectLang: openLang })}>
                {languages.map(({ icon, label, value }) => (
                  <Button key={label} onClick={() => handleSelectedLang(label, icon, value)} className="langBlock">
                    <img src={icon} className="iconFlag" alt="lang" />
                    <span className="lang">{label}</span>
                  </Button>
                ))}
              </div>
            </div>

            <div className="verticalLine" />

            <Button variant="ghost" onClick={handleLogout} className="logoutButton">
              {t('HEADER.logout')}
            </Button>

            <Button
              ref={burgerButtonRef}
              variant="ghost"
              className="burgerButton"
              onClick={(e) => {
                e.stopPropagation();
                handleMenu();
              }}
            >
              {openDropdown ? <CloseIcon color="var(--white)" /> : <Burger />}
            </Button>
          </div>
        </div>
      </div>

      <div ref={menuHeaderRef} className={classNames('menuHeader', { backImg: openDropdown }, { dark: darkMode })}>
        {vw > 1100 && (
          <img
            alt=""
            className="backgroundImage"
            src={`${companyInfo?.menu_background_image || (darkMode ? HeaderImgDark : HeaderImg)}`}
          />
        )}
        <div className="headerMenuContainer">
          {companyInfo ? (
            <Link className="logoLink" to="/app/home">
              <img
                src={currentUser?.data?.role === USER_ROLES.LEVEL_2 ? getLogo(1) : getLogo(2)}
                className="logo"
                alt="logo"
              />
            </Link>
          ) : (
            <div />
          )}

          <div className="navBlock" ref={searchFormRef}>
            {openSearch ? (
              <Button variant="ghost" className="buttonClose" onClick={() => setOpenSearch((prevState) => !prevState)}>
                <CloseIcon darkMode={darkMode} />
              </Button>
            ) : (
              <nav className={classNames('links', { dark: darkMode })}>
                <NavLink to="/app/home" className="link" activeClassName="active" onClick={handleCloseMenu}>
                  {t('HEADER.home')}
                </NavLink>
                <NavLink to="/app/about" className="link" activeClassName="active" onClick={handleCloseMenu}>
                  {t('HEADER.about')}
                </NavLink>
                <Access exact accessRole={USER_ROLES.LEVEL_3}>
                  <NavLink to="/app/products" className="link" activeClassName="active">
                    {t('HEADER.products')}
                  </NavLink>
                </Access>

                {productsData && (
                  <Access accessRole={USER_ROLES.LEVEL_2}>
                    <Button
                      variant="ghost"
                      className={classNames(
                        'link dropdownButton',
                        { colorRed: location.pathname.startsWith('/app/products') },
                        { dark: darkMode }
                      )}
                      onClick={() => setOpenDropdown((prevState) => !prevState)}
                    >
                      {t('HEADER.products')}
                      <MiniArrowTopDown active={openDropdown} darkMode={darkMode} className="arrowDown" />
                    </Button>
                  </Access>
                )}

                <Access accessRole={USER_ROLES.LEVEL_2}>
                  <NavLink to="/app/support" className="link" activeClassName="active" onClick={handleCloseMenu}>
                    {t('HEADER.support')}
                  </NavLink>
                </Access>
                <Access accessRole={USER_ROLES.LEVEL_2}>
                  <NavLink to="/app/downloads" className="link" activeClassName="active" onClick={handleCloseMenu}>
                    {t('HEADER.downloads')}
                  </NavLink>
                </Access>
                <NavLink to="/app/calendar/all" className="link" activeClassName="active" onClick={handleCloseMenu}>
                  {t('HEADER.calendar')}
                </NavLink>
                <NavLink to="/app/contact" className="link" activeClassName="active" onClick={handleCloseMenu}>
                  {t('HEADER.contact')}
                </NavLink>
              </nav>
            )}

            <div className={classNames('verticalBlackLine', { dark: darkMode })} />

            <Formik initialValues={{ search: '' }} validationSchema={SearchSchemaValidation} onSubmit={handleSearch}>
              {(formProps) => <Search {...formProps} open={openSearch} darkMode={darkMode} setOpen={setOpenSearch} />}
            </Formik>
          </div>
        </div>

        <div className={classNames('dropdown', { open: openDropdown })}>
          <div className="drawer">
            <div className={classNames('containerList', { dark: darkMode }, { openListCategory: openCategory })}>
              {!openCategory && (
                <Formik
                  initialValues={{ search: '' }}
                  validationSchema={SearchSchemaValidation}
                  onSubmit={handleSearch}
                >
                  {(formProps) => (
                    <Search {...formProps} open={openSearch} darkMode={darkMode} setOpen={setOpenSearch} />
                  )}
                </Formik>
              )}

              <NavLink to="/app/home" onClick={handleMenu} className="navLink">
                {t('HEADER.home')}
              </NavLink>
              <NavLink to="/app/about" onClick={handleMenu} className="navLink">
                {t('HEADER.about')}
              </NavLink>
              <Access exact accessRole={USER_ROLES.LEVEL_3}>
                <NavLink to="/app/products" onClick={handleMenu} className="navLink">
                  {t('HEADER.products')}
                </NavLink>
              </Access>

              {productsData && (
                <Access accessRole={USER_ROLES.LEVEL_2}>
                  <Button
                    variant="ghost"
                    className={classNames('listBtn', { dark: darkMode }, { leftBtn: openCategory })}
                    onClick={() => handleOpenCategory()}
                  >
                    <ArrowSide className={classNames('arrowLeft', { iconBtnLeft: openCategory })} color={darkMode} />
                    <span>{t('HEADER.products')}</span>
                    <ArrowSide className={classNames('arrowRight', { iconBtnRight: !openCategory })} color={darkMode} />
                  </Button>
                </Access>
              )}

              <Access accessRole={USER_ROLES.LEVEL_2}>
                <NavLink to="/app/support" onClick={handleMenu} className="navLink">
                  {t('HEADER.support')}
                </NavLink>
              </Access>
              <Access accessRole={USER_ROLES.LEVEL_2}>
                <NavLink to="/app/downloads" onClick={handleMenu} className="navLink">
                  {t('HEADER.downloads')}
                </NavLink>
              </Access>
              <NavLink to="/app/calendar/all" onClick={handleMenu} className="navLink">
                {t('HEADER.calendar')}
              </NavLink>
              <NavLink to="/app/contact" onClick={handleMenu} className="navLink">
                {t('HEADER.contact')}
              </NavLink>

              {openCategory && (
                <Link
                  to="/app/products"
                  className={classNames('categoryBtn', { white: darkMode })}
                  onClick={handleMenu}
                >
                  {t('HEADER.allProducts')}
                </Link>
              )}

              {openCategory && (
                <>
                  {categoriesData?.slice(0, 4).map((category) => (
                    <Fragment key={category.id}>
                      <div
                        className={classNames(
                          'categoryBtn',
                          { open: category.id === activeCategory },
                          { white: darkMode }
                        )}
                        onClick={() => handleOpenProducts(category.id)}
                        role="button"
                        aria-hidden="true"
                      >
                        {category.name}
                        <ArrowSide
                          className={classNames('arrowBottom', { arrowTop: activeCategory === category.id })}
                          color={darkMode}
                        />
                      </div>

                      <div className={classNames('productList', { white: darkMode })}>
                        {hiddenProducts &&
                          category.id === activeCategory &&
                          productsData?.items.map((product) => (
                            <Link
                              to={`/app/products/${product.product_category?.name}/${product.id}`}
                              key={product.id}
                              onClick={handleMenu}
                              className="productItem"
                            >
                              {`${product.header_title}`}
                            </Link>
                          ))}
                      </div>
                    </Fragment>
                  ))}
                </>
              )}
            </div>
          </div>

          <div className="dropdownContent">
            <div
              className="leftBlock"
              style={
                images?.products_icon
                  ? { backgroundImage: `url(${images.products_icon})`, backgroundSize: 'cover' }
                  : { backgroundImage: `url(${Comp})` }
              }
            />

            <div className="rightBlock">
              <div className="dropdownMenu">
                <div className="tabs">
                  {categoriesData?.slice(0, 4).map((category) => (
                    <div
                      key={category.id}
                      className={classNames(
                        'tab_item',
                        { dark: darkMode },
                        { active: activeCategory === category.id },
                        { darkActive: activeCategory === category.id && darkMode }
                      )}
                      onClick={handleCategory(category.id)}
                      role="button"
                      aria-hidden="true"
                    >
                      {category.name}
                    </div>
                  ))}
                </div>

                <Link
                  to="/app/products"
                  onClick={() => setOpenDropdown((prevState) => !prevState)}
                  className={classNames('viewAll', { dark: darkMode })}
                >
                  <span className="viewAllText">View all</span>
                  <ArrowRight dark={darkMode} />
                </Link>
              </div>

              <div className={classNames('tab multiColumns', { dark: darkMode })}>
                {productsData?.items.map((product) => (
                  <Link
                    to={`/app/products/${product.product_category?.name}/${product.id}`}
                    key={product.id}
                    onClick={() => setOpenDropdown((prevState) => !prevState)}
                    className="productItem"
                  >
                    {product.header_title}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
});

export default Header;
