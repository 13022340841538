import React, { memo } from 'react';
import { Form, Field } from 'formik';
import classNames from 'classnames';

import Button from 'components/Button';
import SearchIcon from 'components/Icons/SearchIcon';

import './Search.scss';

const name = 'search';

const Search = memo(({ open, setOpen, darkMode, errors, touched }) => {
  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen((prevState) => !prevState);
  };

  return (
    <Form className={classNames('searchContainer', { dark: darkMode })}>
      <Field
        name={name}
        type="text"
        placeholder="search website…"
        className={classNames({ active: open, dark: darkMode })}
      />
      {open && errors[name] && touched[name] && <span className="errMsg">{errors[name]}</span>}
      {open ? (
        <Button type="submit" variant="ghost" className="openButton">
          <SearchIcon darkMode={darkMode} />
        </Button>
      ) : (
        <div className="search">
          <div className={classNames('searchLabel', { dark: darkMode })}>Search</div>
          <Button type="button" variant="ghost" className="openButton" onClick={handleOpen}>
            <SearchIcon darkMode={darkMode} />
          </Button>
        </div>
      )}
    </Form>
  );
});

export default Search;
