import { takeEvery, put, fork } from 'redux-saga/effects';

import API from 'resources/api';
import { GET_DOWNLOAD_FILES } from 'resources/schema';
import { TYPES_DOWNLOAD, downloadSuccess, downloadErr } from 'store/ducks/download';

function* watchDownloadFiles() {
  yield takeEvery(TYPES_DOWNLOAD.DOWNLOAD_REQUEST, handleDownloadFiles);
}

function* handleDownloadFiles() {
  try {
    const downloadData = yield API.get(GET_DOWNLOAD_FILES);
    yield put(downloadSuccess(downloadData.data));
  } catch (e) {
    yield put(downloadErr(e.response));
  }
}

export default function* downloadSaga() {
  yield fork(watchDownloadFiles);
}
