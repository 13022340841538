import { takeEvery, put, fork } from 'redux-saga/effects';

import API from 'resources/api';
import { CONTACT, CONTACT_FORM } from 'resources/schema';
import { TYPES_CONTACT, contactSuccess, contactError } from 'store/ducks/contact';
import { TYPES_CONTACT_FORM, contactFormSuccess, contactFormError } from 'store/ducks/contactFrom';

// GET CONTACT DATA
function* watchContactData() {
  yield takeEvery(TYPES_CONTACT.CONTACT_REQUEST, handleContactData);
}

function* handleContactData() {
  try {
    const contactData = yield API.get(CONTACT);
    yield put(contactSuccess(contactData.data.data));
  } catch (e) {
    yield put(contactError(e.response));
  }
}

// POST CONTACT FORM
function* watchContactFormData() {
  yield takeEvery(TYPES_CONTACT_FORM.CONTACT_REQUEST_FORM, handleContactForm);
}

function* handleContactForm({ payload }) {
  try {
    const formData = yield API.post(CONTACT_FORM, payload);
    yield put(contactFormSuccess(formData.data.status));
  } catch (e) {
    yield put(contactFormError(e.message));
  }
}

export default function* contactSaga() {
  yield fork(watchContactData);
  yield fork(watchContactFormData);
}
