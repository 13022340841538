import { all, fork } from 'redux-saga/effects';

import calendarTimelinesSaga from './calendar';
import searchSaga from './search';
import contactSaga from './contact';
import aboutSaga from './about';
import signUpSaga from './signup';
import loginSaga from './login';
import authSaga from './auth';
import downloadSaga from './download';
import privacyPolicySaga from './privacy';
import specialSagas from './special';
import productsSagas from './products';
import imagesSaga from './images';
import publicPageSaga from './public';
import companyInfoSaga from './companyInfo';
import headerProductsSaga from './headerProducts';
import mediaRichSaga from './mediaRich';
import supportSaga from './support';
import downloadFormSaga from './downloadForm';
import careerEnquiryFormSaga from './careerEnquiryForm';
import supportFormSaga from './supportForm';

const sagas = [
  calendarTimelinesSaga,
  searchSaga,
  contactSaga,
  aboutSaga,
  signUpSaga,
  loginSaga,
  authSaga,
  downloadSaga,
  privacyPolicySaga,
  specialSagas,
  productsSagas,
  imagesSaga,
  publicPageSaga,
  companyInfoSaga,
  headerProductsSaga,
  mediaRichSaga,
  supportSaga,
  downloadFormSaga,
  careerEnquiryFormSaga,
  supportFormSaga,
];

export default function* rootSaga(services = {}) {
  yield all(sagas.map((saga) => fork(saga, services)));
}
