import React from 'react';
import ReactDOM from 'react-dom';

import Root from 'containers/root';

import '@fortawesome/fontawesome-free/css/all.min.css';
import './index.scss';

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById('root')
);
